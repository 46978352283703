import React from "react";
import AppContent from "./AppContent";
import AppSidebar from "./AppSidebar";
import AppTopbar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppReportIssue from "./AppReportIssue";
import {Localization} from "react-widgets/esm";
import {DateLocalizer} from "react-widgets/IntlLocalizer";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {getMomentCode} from "../app-components/patient/DictsDecode";


export function AppWrapper() {

    const {t} = useTranslation()

    return (
        <>
            <Localization
                date={new DateLocalizer({
                    firstOfWeek: 1,
                    culture: getMomentCode(i18n.resolvedLanguage)
                })}
                messages={{
                    moveToday: t('reactWidgets.moveToday'),
                    moveBack: t('reactWidgets.moveBack'),
                    moveForward: t('reactWidgets.moveForward'),
                    emptyList: t('reactWidgets.emptyList'),
                    emptyFilter: t('reactWidgets.emptyFilter')
                }}
            >
                <AppTopbar/>

                <div id="wrapper">
                    <AppSidebar/>
                    <AppContent/>
                    <AppReportIssue/>
                    <div id="modal-root"></div>
                </div>

                <AppFooter/>
            </Localization>
        </>
    );
}
