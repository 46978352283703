import React, {useState} from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {NavLink, withRouter} from "react-router-dom";
import {Navbar} from "reactstrap";
import useAdminRoutes from "../hooks/useAdminRoutes";
import "assets/css/sidebar.css"
import _ from 'lodash'
import usePersonnelRoutes from "../hooks/usePersonnelRoutes";
import usePersonnelPatientRoutes from "../hooks/usePersonnelPatientRoutes";
import {AppMobileVersion} from "./AppMobileVersion";
import {useTranslation} from "react-i18next";
import AppLanguage from "./AppLanguage";

const AppSidebar = (props) => {
    const [navToggled, setNavToggled] = useState(false)
    const routesAdmin = useAdminRoutes(props.tenant)
    const routes = usePersonnelRoutes(props.tenant)
    const routesPersonnelPatient = usePersonnelPatientRoutes(props.tenant)
    const [getMobileAppModalOpen, setGetMobileAppModalOpen] = useState(false)
    const [langModalIsOpen, setLangModalIsOpen] = useState(false)
    const {t} = useTranslation()


    const checkAuthorities = (r) => {

        if (!props.user.authorities) return false
        const userAuthorities = props.user.authorities

        const has = _.filter(r.has, function (p) {
            return userAuthorities.includes(p)
        }).length > 0

        const noHas = r.noHas ? _.filter(r.noHas, function (p) {
            return userAuthorities.includes(p)
        }).length === 0 : true

        const andHas = r.andHas ? _.filter(r.andHas, function (p) {
            return userAuthorities.includes(p)
        }).length > 0 : true

        return has && noHas && andHas
    };

    const toggle = () => {
        document.getElementsByTagName("body")[0].classList.toggle("sidebar-toggled");
        document.getElementsByClassName("sidebar")[0].classList.toggle("toggled");
        setNavToggled(!navToggled)

    };

    const logout = () => {
        props.clearUser();
        props.history.push('/');
        props.keycloak.logout();
    };

    const closeSidebarOnMobile = () => {
        if (props.windowSize.width < 991) {
            props.closeSidebar()
        }
    };

    const clearValidation = () => {
        props.clearValidation();
    };


    const toggleGetMobileVersion = () => {
        setGetMobileAppModalOpen(!getMobileAppModalOpen)
    };


    const renderNavLink = (item) => {
        return <React.Fragment key={item.key}>{(props.windowSize.width >= 991 ? true : item.showOnMobile) &&
            <li className="nav-item sidebar-link" role="presentation" key={item.key}
                onClick={closeSidebarOnMobile}>

                <AppMobileVersion isOpen={getMobileAppModalOpen}
                                  toggle={toggleGetMobileVersion}
                />

                <NavLink className="nav-link" exact to={item.path} onClick={clearValidation}>
                    <div className="row">
                        <div className={navToggled ? "col-12" : "col-12 col-md-2"}>
                            <i className={item.icon + " pl-2 pr-2"}/>
                        </div>
                        <div className={"label " + (navToggled ? "col-12" : "col-12 col-md-10")}>
                            <span className="fw400">{item.label}</span>
                        </div>
                    </div>
                </NavLink>
            </li>} </React.Fragment>
    }

    const logoutButtonForMobile = () => {
        return <>
            {props.windowSize.width < 991 ?
                <li className="nav-item sidebar-link" role="presentation" onClick={() => {
                    logout();
                }}>
                            <span className="nav-link pointer">
                                <div className="row">
                                    <div className="col-12 col-md-2">
                                        <i className="fas fa-sign-out pl-2 pr-2"/>
                                    </div>
                                    <div className="col-12 col-md-10">
                                        <span className="fw700">{t("logout")}</span>
                                    </div>
                                </div>
                            </span>
                </li>

                :
                null
            }
        </>;
    }

    const mobileAppButton = () => {
        return <>
            {props.windowSize.width < 991 ?
                <li className="nav-item sidebar-link" role="presentation" onClick={toggleGetMobileVersion}>
                            <span className="nav-link pointer">
                                <div className="row text-gray-700">
                                    <div className="col-12 col-md-2">
                                        <i className="fas fa-mobile pl-2 pr-2"/>
                                    </div>
                                    <div className="col-12 col-md-10">
                                        <span className="fw700">{t("mobileApp")}</span>
                                    </div>
                                </div>
                            </span>
                </li>
                :
                null
            }
        </>
    }

    const changeLangAppButton = () => {
        return <>
            {props.windowSize.width < 991 ?
                <li className="nav-item sidebar-link" role="presentation" onClick={() => setLangModalIsOpen(true)}>
                            <span className="nav-link pointer">
                                <div className="row text-gray-700">
                                    <div className="col-12 col-md-2">
                                        <i className="fas fa-mobile pl-2 pr-2"/>
                                    </div>
                                    <div className="col-12 col-md-10">
                                        <span className="fw700">{t("languages")}</span>
                                    </div>
                                </div>
                            </span>
                </li>
                :
                null
            }
        </>
    }

    let kc = props.keycloak;
    return (
        <Navbar
            className={"navbar align-items-start sidebar accordion p-0 mt-1 sidebar-menu " + (props.sidebar.sidebarOpen ? "slide-in-left " : "slide-out-left ")}
            style={Object.assign({}, (props.windowSize.width < 991 ? {
                position: "absolute", height: "100%"
            } : {position: "unset"}), {zIndex: 1001})}>

            <AppLanguage isOpen={langModalIsOpen}
                         toggle={() => setLangModalIsOpen(false)}/>

            <div style={{position: "relative", height: "100%", width: "100%"}}>
                <div onClick={toggle} className="d-flex align-items-center justify-content-center"
                     style={{
                         position: "absolute",
                         right: -10,
                         top: "50vh",
                         width: 20,
                         height: 40,
                         backgroundColor: "#FFF",
                         border: "2px solid #EFF5F6",
                         borderRadius: "6px",
                         zIndex: 1
                     }}>
                    <i className={"text-secondary pointer fas " + (navToggled ? "fa-chevron-right" : "fa-chevron-left")}/>
                </div>
                <div className="container-fluid d-flex flex-column p-0">
                    <ul className="nav navbar-nav" id="accordionSidebar">
                        {routes.filter(r => r.featureFlagsShow)
                            .filter((r) => r.sidebar)
                            .filter((r) => checkAuthorities(r))
                            .map((r) => renderNavLink(r))}
                        {
                            ((kc.resourceAccess.core.roles.includes('admin') || kc.resourceAccess.core.roles.includes('superadmin')) && routesAdmin.filter(r => r.featureFlagsShow).filter((r) => r.sidebar)
                                .filter((r) => checkAuthorities(r))
                                .map((r) => renderNavLink(r)))
                        }
                        {props.tenant.featureFlags?.mobileApp && mobileAppButton()}
                        {changeLangAppButton()}
                        {logoutButtonForMobile()}
                        <li className="nav-item sidebar-link fw700" role="presentation" key="comorbidities">
                            <hr/>
                        </li>
                        {props.context.patient ? routesPersonnelPatient.filter((r) => r.sidebar)
                            .filter((r) => checkAuthorities(r))
                            .filter(r => r.featureFlagsShow)
                            .map((r) => renderNavLink(r)) : ''}
                    </ul>
                </div>
            </div>
        </Navbar>
    );
}


export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppSidebar));
