import qs from 'query-string';
import {ajaxStart, ajaxStop, clearUser, pushToast, setValidation, setValueOnForm, store} from 'app/redux/redux'
import Logger from 'app/Logger'
import i18n from 'i18next';


export class Api {

    static apiUrl = "";

    constructor(promise) {
        this.p = promise;
        this.store = {};
    }

    static fetch(url, options) {
        store.dispatch(ajaxStart());
        return new this(fetch(this.apiUrl + url, options));
    }

    static get(url) {
        store.dispatch(ajaxStart());
        Logger.debug(`GET [${url}]`);

        return new this(fetch(
            this.apiUrl + url,
            {
                credentials: 'include',
                headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
            })
        );
    }

    static getFile(url) {
        store.dispatch(ajaxStart());
        Logger.debug(`GET [${url}]`);

        return fetch(
            this.apiUrl + url,
            {
                credentials: 'include',
                headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
            })
    }

    static getImagesSecured(url) {
        store.dispatch(ajaxStart());
        Logger.debug(`GET [${url}]`);

        return new this(fetch(url,
            {
                credentials: 'include',
                headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
            })
        );
    }

    static delete(url) {
        store.dispatch(ajaxStart());
        Logger.debug(`DELETE [${url}]`);
        return new this(fetch(
            this.apiUrl + url,
            {
                method: 'DELETE',
                credentials: 'include',
                headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
            })
        );
    }

    static post(url, payload, withoutAjax) {
        if (withoutAjax !== true) {
            store.dispatch(ajaxStart());
        }
        Logger.debug(`POST [${url}] payload:`, payload);
        return new this(fetch(this.apiUrl + url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(payload),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().keycloak.token
            }
        }));
    }

    static patch(url, payload, withoutAjax) {
        if (withoutAjax !== true) {
            store.dispatch(ajaxStart());
        }
        Logger.debug(`PATCH [${url}] payload:`, payload);
        return new this(fetch(this.apiUrl + url, {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify(payload),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().keycloak.token
            }
        }));
    }

    static put(url, payload) {
        store.dispatch(ajaxStart());
        Logger.debug(`PUT [${url}] payload:`, payload);
        return new this(fetch(this.apiUrl + url, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(payload),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().keycloak.token
            }
        }));
    }

    static postForm(url, payload) {
        store.dispatch(ajaxStart());
        Logger.debug(`POST [${url}] payload:`, payload);
        return new this(fetch(this.apiUrl + url, {
            credentials: 'include',
            method: 'POST',
            body: qs.stringify(payload),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().keycloak.token
            },
        }));
    }

    static sendFilesWithData(url, payload) {
        store.dispatch(ajaxStart());
        Logger.debug(`POST [${url}] payload: file`);
        return new this(fetch(this.apiUrl + url, {
            credentials: 'include',
            method: 'POST',
            body: payload,
            headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
        }));
    }

    static sendFile(url, file) {
        let formData = new FormData();
        formData.append("file", file);
        store.dispatch(ajaxStart());
        Logger.debug(`POST [${url}] payload: file`);
        return new this(fetch(this.apiUrl + url, {
            credentials: 'include',
            method: 'POST',
            body: formData,
            headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
        }));
    }

    then(callback, errHandler, withoutAjax, omitMessageCheck) {
        this.p
            .then(response => {
                if (withoutAjax !== true) {
                    store.dispatch(ajaxStop());
                }
                if (!response.ok) {
                    let error = Error(response.status);
                    error.status = response.status;
                    error.response = response;
                    throw error;
                }
                Logger.info("Fetch OK:", response.url);
                return response;
            })
            .then(response => response.json())
            .then(json => {
                Logger.debug("Fetch OK:", json);
                if (!omitMessageCheck) {
                    json.meta.messages.forEach(m => {
                        store.dispatch(pushToast(m.type.substring(0, 1).toLowerCase(), '' + m.text));
                    });
                }
                return callback(json);
            })
            .catch(error => {
                if (error.status === 403) {
                    store.dispatch(setValidation([
                        {field: "username", msg: i18n.t('wrongEmailOrPassword')}
                    ]));
                    error.message = i18n.t('wrongEmailOrPassword');
                    store.dispatch(clearUser());
                    return
                }

                if (error.status === 401) {
                    store.dispatch(clearUser())
                }

                if (error.status === 404) {
                    // store.dispatch(pushToast('e', T('Error: 404 - not found')));
                }

                if (error.status === 500) {
                    error.response.json().then((r) => {
                        Logger.error(r);
                        store.dispatch(setValueOnForm('FORM_NEW_ISSUE', 'uuid', r.message))
                        store.dispatch(pushToast('e', error.status + " " + i18n.t(r.error) + ": " + i18n.t(r.message)));
                    })
                }

                errHandler && errHandler(error.message);

                Logger.error('Fetch error:', error);
            });
    }
}

export const securedImg = (src) => {
    const token = store.getState().keycloak.token
    return src + `?access_token=${token}`
}
