import {useTranslation} from "react-i18next";
import {lazy} from "react";
import ViewVisitsReports from "../app-views/authorized/admin/ViewVisitsReports";

const ViewAddPatient = lazy(() => import ("../app-views/authorized/personnel/ViewAddPatient"));
const ViewPatients = lazy(() => import("../app-views/authorized/personnel/ViewPatients"));
const ViewHome = lazy(() => import("../app-views/authorized/personnel/ViewHome"));
const ViewClinicSchedule = lazy(() => import("../app-views/authorized/personnel/ViewClinicSchedule"));
const ViewAccount = lazy(() => import("../app-views/authorized/personnel/ViewAccount"));
const ViewPersonnelVisitDetails = lazy(() => import("../app-views/authorized/personnel/ViewPersonnelVisitDetails"));

const usePersonnelRoutes = (props) => {
    const {t} = useTranslation()
    return [{
        key: 'home',
        exact: true,
        path: "/",
        redirect: "/clinic-schedule",
        has: ['md', 'nurse'],
        component: ViewHome,
        sidebar: true,
        label: t("sidebar.personnel.plannedVisits"),
        icon: 'fas fa-calendar-alt',
        shouldSensitiveDataVisible: false,
        featureFlagsShow: true
    },
        {
            key: 'clinic-schedule',
            exact: true,
            path: "/",
            has: ['receptionist'],
            noHas: ['md', 'nurse'],
            component: ViewClinicSchedule,
            sidebar: true,
            label: t("sidebar.personnel.appointmentScheduler"),
            icon: 'fas fa-calendar-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'clinic-schedule',
            exact: true,
            path: "/schedule",
            has: ['nurse'],
            andHas: ['receptionist'],
            component: ViewClinicSchedule,
            sidebar: true,
            label: t("sidebar.personnel.appointmentScheduler"),
            icon: 'fas fa-calendar-week',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'clinic-schedule',
            exact: true,
            path: "/schedule",
            has: ['md'],
            component: ViewClinicSchedule,
            sidebar: true,
            label: t("sidebar.personnel.appointmentScheduler"),
            icon: 'fas fa-calendar-week',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: props?.featureFlags?.scheduleVisitsByDoctor === false ? props.featureFlags.scheduleVisitsByDoctor : true
        },
        {
            key: 'accountData',
            exact: true,
            path: "/account",
            has: ['md', 'nurse', 'receptionist', 'admin'],
            component: ViewAccount,
            sidebar: false,
            label: t("sidebar.personnel.accountData"),
            icon: 'fas fa-calendar-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'addPatient',
            exact: true,
            path: "/addPatient",
            has: ['md', 'nurse', 'receptionist'],
            component: ViewAddPatient,
            sidebar: true,
            label: t("sidebar.personnel.addPatient"),
            icon: 'fas fa-user-plus',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'patients',
            exact: true,
            path: "/patients",
            has: ['md', 'nurse', 'receptionist'],
            component: ViewPatients,
            sidebar: true,
            label: t("sidebar.personnel.patients"),
            icon: 'fas fa-user-friends',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'personnelVisits',
            exact: true,
            path: "/personnel-visits",
            has: ['nurse'],
            component: ViewPersonnelVisitDetails,
            sidebar: true,
            label: t("sidebar.personnel.personnelVisits"),
            icon: 'fas fa-user-md',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'reports',
            exact: true,
            path: "/reports",
            has: ['md', 'nurse', 'receptionist'],
            component: ViewVisitsReports,
            sidebar: true,
            label: t("sidebar.reports"),
            icon: 'far fa-file-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: props?.featureFlags?.visitsReports === true
        }
    ];
}
export default usePersonnelRoutes
