import React from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {withRouter} from "react-router-dom";
import {PersonnelApi} from "../api/PersonnelApi";
import AppModal from "./AppModal";
import FormRowText from "../app-components/common/Form/FormRowText";
import {FormRow} from "../app-components/common/Form/FormRow";
import {Translation} from 'react-i18next';

class AppTopbarVisit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isTrashClicked: false,
        }
    }

    componentDidMount() {
        PersonnelApi.getPatientVisitSnapshot(this.props.context.patient.patient.id, this.props.user.userInfo.id, this.props.user.clinic.id).then(r => {
            let unsavedVisit = r.data && r.data.visitSnapshot
            this.props.setContext({...this.props.context, unsavedVisit: unsavedVisit});
        })
    }

    deleteUnsavedVisit() {
        PersonnelApi.deletePatientVisitSnapshot(this.props.context.patient.patient.id, this.props.user.userInfo.id, this.props.user.clinic.id).then(_ => {
            this.props.setContext({...this.props.context, unsavedVisit: false});
            this.props.history.push('/');
        })
    }

    goToUnsavedVisit() {
        PersonnelApi.getPatientVisitSnapshot(this.props.context.patient.patient.id, this.props.user.userInfo.id, this.props.user.clinic.id).then(r => {
            this.props.setContext({...this.props.context, unsavedVisit: true});
            this.props.setForm('newVisit', r.data.visitSnapshot);
            this.props.history.push('/newVisit');
        })
    }

    closeUnsavedVisit() {
        this.setState({
            isTrashClicked: false
        })
    }

    render() {
        let showContinue = this.props.history.location.pathname !== '/newVisit';
        return <Translation>
            {(t, {i18n}) => <>
                {this.state.isTrashClicked ?
                    <AppModal title={t("sureToDeleteVisit")}
                              isOpen={this.state.isTrashClicked}
                              saveText={t("common.yes")}
                              cancelText={t("common.no")}
                              toggle={() => this.closeUnsavedVisit()}
                              saveAction={() => this.deleteUnsavedVisit()}
                              className="modal-lg">
                        <FormRow>
                            <FormRowText>
                                {t("unsavedVisitExists")}
                            </FormRowText>
                        </FormRow>
                    </AppModal> : <></>}

                {this.props.context.unsavedVisit ?
                    <span className="p-0 pr-3 pl-3 h-100 align-items-center d-flex"
                          style={{borderRight: "1px solid #DDD"}}>
                            <span className="pr-2">{t("visitBegun")}</span>
                        {showContinue ? <span className="pl-2">
                                <span onClick={() => {
                                    this.goToUnsavedVisit()
                                }} className=" pointer text-gray-600 p-0">
                                    <i className="far fa-play-circle fa-lg"/>
                                </span>
                            </span> : ''}
                        <span className="pl-2">
                                <span onClick={() => {
                                    this.setState({isTrashClicked: true})
                                }} className=" pointer text-gray-600 p-0">
                                    <i className="far fa-trash-alt fa-lg"/>
                                </span>
                            </span>
                        </span> : ''}
            </>
            }
        </Translation>

    }
}


export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppTopbarVisit));