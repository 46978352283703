import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import {PersonnelApi} from "../../../api/PersonnelApi";
import _ from "lodash";
import Utils from "../../../Utils";
import {FormRowCol} from "../../common/Form/FormRowCol";
import NewVisitWoundClassificationDetails from "./newVisit/wound/NewVisitWoundClassificationDetails";
import {FormRow} from "../../common/Form/FormRow";
import NewVisitWoundClassificationPreviewHistory from "./newVisit/wound/NewVisitWoundClassificationPreviewHistory";
import {useTranslation} from "react-i18next";

const WoundsClassification = (props) => {

    const [items, setItems] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        PersonnelApi.getWoundsClassiff(props.wound.id).then(r => {
            setItems(r.data)
            setItemsLoaded(true)
        })
    }, [props.wound.id])

    if (!itemsLoaded) return ''

    return _.map(items, (c, index) => {
        let personnel = c.visitDetails.personnel
        return <FormRow key={index}>
            <FormRowCol col={12}>
                <div className="card mb-2">
                    <div className="card-header d-flex">
                        <span
                            className="fw700">{c.visitDetails.clinic.name + " - "+ personnel.degree + " " + personnel.firstname + " " + personnel.lastname} - {t("visitDateText")}: {Utils.getFormattedDate(c.visitDetails.visit.visitDate, 'YYYY-MM-DD')}</span>
                    </div>
                    <div className="card-body">
                        <FormRow>
                            <FormRowCol col={12} className="col-lg-4">
                                <NewVisitWoundClassificationPreviewHistory planimetries={c.classif.planimetries}/>
                            </FormRowCol>
                            <FormRowCol col={12} className="col-lg-8">
                                <NewVisitWoundClassificationDetails classif={c.classif}/>
                            </FormRowCol>
                        </FormRow>
                    </div>
                </div>
            </FormRowCol>
        </FormRow>
    })


}


export default connect(mapStorageToProps, mapStorageDispatchToProps)(WoundsClassification)
