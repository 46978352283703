import React, {Component} from "react";
import {Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {getMsg, isValid} from "../Validation";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import _ from 'lodash'
import * as PropTypes from "prop-types";
import {getValueFromFormOrDefault} from "./FormUtils";
import {Translation} from 'react-i18next';

class FormSelect extends Component {
    static defaultProps = {
        colLg: 6
    };

    checkExistingField(field) {
        return !this.props.form[this.props.formId] || !this.props.form[this.props.formId][field]
    }

    componentDidMount() {
        if (this.props.defaultValueCallback) {
            const findElement = this.props.options.find(this.props.defaultValueCallback);
            if (findElement) {
                this.props.setValueOnForm(this.props.formId, this.props.field,
                    findElement[this.props.optionValueField]);
            }
        }
    }

    getSortedOptions() {
        if (!this.props.colorSelectedOption) {
            return this.props.options
        } else {
            return this.props.options.sort((a, b) => {
                if (a.value === this.props.selectedOptionToColor) {
                    return -1;
                } else if (b.value === 9) {
                    return 1;
                } else {
                    return a.value - b.value;
                }
            });
        }

    }

    render() {
        let props = this.props;
        let invalid = isValid(props.field);
        return <Translation>
            {(t) =>
                <div className={"col-md-12 mt-2 col-lg-" + props.colLg}>
                    <FormGroup row>
                        <Label for={props.formId + '.' + props.field} sm={props.sizeLabel}
                               className="text-md-right text-label">{props.label}{props.required &&
                            <span className="fred pl-1">*</span>}</Label>
                        <Col sm={props.sizeCol} className="my-auto">
                            <Input className="pointer custom-select border-1" type="select" name={props.field}
                                   id={props.formId + '.' + props.field}
                                   invalid={invalid}
                                   onChange={(e) => {
                                       e.persist();
                                       props.beforeOnChange && props.beforeOnChange(e.target.value);
                                       props.setValueOnForm(props.formId, props.field, e.target.value);
                                       props.afterOnChange && props.afterOnChange(e.target.value);
                                   }}
                                   style={{backgroundColor: props.disabled ? "#eee" : "none"}}
                                   value={getValueFromFormOrDefault(props.formId, props.field, "")}
                                   disabled={props.disabled || (props.disabledIfNotFillField && this.checkExistingField(props.disabledIfNotFillField))}
                            >
                                {props.selectOptions && <option className="pointer" value="">{t("select")}</option>}
                                {_.map(this.getSortedOptions(), (option, i) => {
                                    return <option
                                        style={{backgroundColor: (this.props.colorSelectedOption && option.value === this.props.selectedOptionToColor ? '#d2f8d2' : 'white')}}
                                        value={option[this.props.optionValueField]}
                                        key={i}>{option[this.props.optionLabelField]}</option>
                                })}

                            </Input>
                            {invalid && <FormFeedback style={{
                                display: "block",
                                marginLeft: '10px'
                            }}>{getMsg(props.field)}</FormFeedback>}
                        </Col>
                    </FormGroup>
                </div>}
        </Translation>;
    }
}

FormSelect.defaultProps = {
    colLg: 6,
    disabled: false,
    required: false,
    optionValueField: 'value',
    optionLabelField: 'label',
    sizeLabel: 3,
    sizeCol: 9,
    selectOptions: true,
    colorSelectedOption: false,
};

FormSelect.propTypes = {
    afterOnChange: PropTypes.func,
    beforeOnChange: PropTypes.func,
    colLg: PropTypes.number,
    disabled: PropTypes.bool,
    disabledIfNotFillField: PropTypes.string,
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array,
    optionValueField: PropTypes.string,
    optionLabelField: PropTypes.string,
    required: PropTypes.bool,
    sizeLabel: PropTypes.number,
    sizeCol: PropTypes.number,
    defaultValueCallback: PropTypes.func,
    selectOptions: PropTypes.bool,
    colorSelectedOption: PropTypes.bool,
    selectedOptionToColor: PropTypes.number
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormSelect)
