import {Route, withRouter} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {Alert} from "reactstrap";
import {store} from "../redux/redux";

const AppSecureRoute = ({component: Component, props, ...rest}) => {
    return (
        <>
            <Route {...rest} render={(props) => {
                const shouldSensitiveDataVisible = rest.shouldSensitiveDataVisible
                store.dispatch({
                    type: 'SET_IS_SENSITIVE_DATA_VISIBLE',
                    isSensitiveDataVisible: shouldSensitiveDataVisible
                })
                return (
                    rest.user
                        ? <Component {...props} />
                        : <Alert color="warning"><i className="fas fa-exclamation-circle"/> Insufficient privileges.
                            Please contact your Administrator.</Alert>
                );
            }}/>
        </>
    );
};

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppSecureRoute))