import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";
import {cloneWithFormId} from "./FormUtils";


class FormCol extends Component {
    render() {
        return <div className="col my-auto">{this.props.children}</div>
    }
}

FormCol.propTypes = {
    children: PropTypes.node
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormCol)