import {Api} from "./Api";

export class TenantApi extends Api {


    static apiUrl = window.var.API_HOST + '/api/v1/tenant';

    static getTenants() {
        return TenantApi.get("")
    }

    static getTenant(tenantId) {
        return TenantApi.get("/" + tenantId)
    }

}

