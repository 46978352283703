import React from 'react';
import {ajaxStart, ajaxStop, store} from "../../redux/redux";

export function AuthenticatedLink({url, filename, children}) {

    const handleAction = async () => {
        const link = document.createElement("a");
        document.body.appendChild(link);

        store.dispatch(ajaxStart());

        const result = await fetch(window.var.API_HOST + url, {
            headers: new Headers({'Authorization': 'Bearer ' + store.getState().keycloak.token})
        })

        const blob = await result.blob()
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("type", "hidden");
        link.setAttribute("download", filename);
        link.click();

        store.dispatch(ajaxStop());

    }

    return (
        <>
            <a role='button' onClick={handleAction} 
               style={{textDecoration: "none"}}>{children}</a>
        </>
    )
}