import React, {Component} from "react";
import {cloneWithFormId} from "./FormUtils";
import * as PropTypes from "prop-types";

export class FormRow extends Component {
    render() {
        return <>
            <div className={"row bgr-white mx-3 mt-2" + this.props.className} style={this.props.style}>
                {cloneWithFormId(this.props.children, this.props.formId)}
            </div>
        </>;
    }
}

FormRow.defaultProps = {
    className: '',
    style: {}
}

FormRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.object
};