import React from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {withRouter} from "react-router-dom";
import AppModal from "./AppModal";
import FormWrapper from "../app-components/common/Form/FormWrapper";
import {FormSection} from "../app-components/common/Form/FormSection";
import {FormRow} from "../app-components/common/Form/FormRow";
import FormSelect from "../app-components/common/Form/FormSelect";
import {existValueOnForm, getValueFromFormOrDefault} from "../app-components/common/Form/FormUtils";
import _ from "lodash";
import {PersonnelApi} from "../api/PersonnelApi";
import moment from "moment";
import Utils from "../Utils";
import {Translation} from 'react-i18next';

const CHANGE_CLINIC_FORM = 'changeClinic'

class AppTopbarClinic extends React.Component {
    constructor(props) {
        super(props);
        let clinicSelected = props.user.clinic && props.user.clinic.id;
        this.state = {
            openChangeClinic: !clinicSelected,
            clinics: []
        }
    }

    componentDidMount() {
        PersonnelApi.getAllClinicForPersonnel(this.props.user.userInfo.id).then(json => {
            this.loadClinicContextIfOnlyOne(json.data);
            this.tryLoadClinicContextFromLocalStorage(json.data);
            this.setState({clinics: json.data});
        })


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user.clinics !== undefined && prevProps.user.clinics.length !== this.props.user.clinics.length) {
            PersonnelApi.getAllClinicForPersonnel(this.props.user.userInfo.id).then(json => {
                this.loadClinicContextIfOnlyOne(json.data);
                this.tryLoadClinicContextFromLocalStorage(json.data);
                this.setState({clinics: json.data});
            })

        }

    }

    loadClinicContextIfOnlyOne(clinics) {
        if (clinics.length === 1) {
            this.props.setUser({
                ...this.props.user,
                clinic: clinics[0],
                clinics: clinics
            });
            this.setState({
                openChangeClinic: false
            });
            localStorage.setItem('clinicId', clinics[0].id)
        }
    }

    tryLoadClinicContextFromLocalStorage(clinics) {
        let clinicIdLocalStorage = parseInt(localStorage.getItem('clinicId'));
        if (clinicIdLocalStorage) {
            let clinic = _.first(_.filter(clinics, (c) => {
                return c.id === clinicIdLocalStorage
            }))
            if (clinic) {
                this.props.setUser({
                    ...this.props.user,
                    clinic: clinic,
                    clinics: clinics
                });
                this.setState({
                    openChangeClinic: false
                });
                let patientId = parseInt(localStorage.getItem('patientId'));
                if (patientId && !Utils.isAdmin()) {
                    PersonnelApi.getPatientData(patientId, clinic.id).then(r => {
                        if (r.data) {
                            let patient = {...r.data}
                            patient.personalData.birth = Utils.utcToDate(patient.personalData.birth)
                            this.props.setContextPatient(patient);
                        }
                    })
                }
            }
        }
    }

    toggleChangeClinic = () => {
        this.setState({
            openChangeClinic: !this.state.openChangeClinic
        })
    }

    changeClinic = () => {
        let id = parseInt(getValueFromFormOrDefault(CHANGE_CLINIC_FORM, 'id', undefined))
        let clinics = this.state.clinics;
        let clinic = _.first(_.filter(clinics, (p) => {
            return p.id === id
        }))
        this.props.setUser({
            ...this.props.user,
            clinic: clinic,
            clinics: this.state.clinics
        })
        this.props.clearContext();
        localStorage.setItem('clinicId', id);
        this.goToHome()

        let newParams = this.props.dataTable['scheduledVisitHome_params'];
        if (newParams) {
            newParams.filters.clinicId.value = clinic.id;
            this.props.setDataTableParams(newParams, 'scheduledVisitHome');
            this.props.updateDataTable('scheduledVisitHome')
        }

        const date = moment(this.props.form.ClinicSchedule?.date).toDate();
        this.props.setForm("ClinicSchedule", {date: date});

    }

    goToHome() {
        this.props.history.push('/');
    };

    render() {
        return <Translation>
            {(t, {i18n}) => <>
                <AppModal className="modal-lg"
                          isOpen={this.state.openChangeClinic}
                          saveText={t("select")}
                          cancelText={t("commonForm.buttonCancel")}
                          title={t("chooseHospital")}
                          toggle={this.toggleChangeClinic}
                          saveAction={this.changeClinic}
                          cancelDisabled={!(this.props.user.clinic && this.props.user.clinic.id)}
                          saveDisabled={!existValueOnForm(CHANGE_CLINIC_FORM, 'id', undefined)}>
                    <FormWrapper formId={CHANGE_CLINIC_FORM}>
                        <FormSection>
                            <FormRow>
                                <FormSelect colLg={12}
                                            label={t("medicalFacility")}
                                            field="id"
                                            options={this.props.user.clinics !== undefined ? this.props.user.clinics : this.state.clinics}
                                            optionValueField='id'
                                            optionLabelField='name'/>
                            </FormRow>
                        </FormSection>
                    </FormWrapper>
                </AppModal>
                {this.props.user.clinic ?
                    <span className="p-0 pr-3 pl-3 ml-4 h-100 align-items-center d-flex"
                          style={{
                              borderRight: "1px solid #DDD",
                              borderLeft: "1px solid #DDD"
                          }}>
                        <span className={this.props.windowSize.width < 991 ? "topbar-span" : "pr-2"}>
                           {this.props.user.clinic.name}
                        </span>
                            <span className="pl-2">
                                {this.props.user.clinics.length > 1 ?
                                    <span onClick={this.toggleChangeClinic} className=" pointer text-gray-600 p-0">
                                    <i className="far fa-exchange-alt fa-lg"/>
                                </span> : ''}
                            </span>
                        </span> : <></>}</>
            }
        </Translation>
    }

}


export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppTopbarClinic));
