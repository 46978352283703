import React, {Component} from "react";
import {Col, FormGroup, Label} from "reactstrap";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";


class FormRowField extends Component {
    static defaultProps = {
        colLg: 6
    };

    render() {
        let props = this.props;
        return <>
            <div className={"col-md-12 mt-2 col-lg-" + props.colLg}>
                <FormGroup row className={"h-100 " + this.props.formGroupClass}>
                    <Label sm={this.props.colLabel}
                           className="text-md-right my-auto text-label">{props.label}{props.required &&
                    <span className="fred pl-1">*</span>}</Label>
                    <Col sm={this.props.colValue} className="my-auto">
                        <b data-test={window.var.DEV_MODE ? `${this.props.label}-children`: null}>{this.props.children}</b>
                    </Col>
                </FormGroup>
            </div>
        </>;
    }
}

FormRowField.defaultProps = {
    colLg: 6,
    colLabel: 3,
    colValue: 9,
    formGroupClass: ""
};

FormRowField.propTypes = {
    colLg: PropTypes.number,
    colLabel: PropTypes.number,
    colValue: PropTypes.number,
    label: PropTypes.string,
    formGroupClass: PropTypes.string
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormRowField)