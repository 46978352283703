import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";


class FormHeader extends Component {
    printCardIndex() {
        let formId = this.props.formId;
        return this.props.form[formId] && this.props.form[formId]['allSteps'] ?
            <span
                className="text-muted h6"> ({this.props.form[formId]["currentStep"]}/{this.props.form[formId]["allSteps"]})</span>
            : '';
    }

    render() {
        return <>
            <div className="card-header bg-secondary">
                <div className="h5 fwhite fw600">
                    {this.props.title}
                    {this.printCardIndex()}

                </div>
            </div>
        </>;
    }
}

FormHeader.propTypes = {
    title: PropTypes.string
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormHeader)