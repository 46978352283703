import React from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {dismissToast, store} from "app/redux/redux";
import {Toast, ToastBody, ToastHeader} from "reactstrap";
import Logger from 'app/Logger'
import {useTranslation} from "react-i18next";

const Toasts = (props) => {

    const {t} = useTranslation()

    const toggleAlert = (toast) => {
        return () => {
            Logger.debug(toast);
            store.dispatch(dismissToast(toast))
        }
    };

    return <div style={{top: 60, right: 5, position: 'fixed', zIndex: 1000000}}>
        <div style={{overflow: "visible"}}>
            {props.toast.map((toast, key) => {

                return <Toast key={key}>
                    <ToastHeader icon={toast.type === "e" ? "danger" : "success"} toggle={toggleAlert(toast)}>
                        {toast.type === "e" ? t("toast.error") : t("toast.information")}
                    </ToastHeader>
                    <ToastBody>
                        {t(toast.msg)}
                    </ToastBody>
                </Toast>
            })}
        </div>
    </div>

}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Toasts)
