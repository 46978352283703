import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";


class FormRowText extends Component {
    render() {
        return <div className="row bgr-white mx-1 px-2 fselected w-100">{this.props.children}</div>
    }
}

FormRowText.propTypes = {
    children: PropTypes.node
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormRowText)