import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import {Button, ButtonGroup, Col, Container, Input, Row} from "reactstrap";
import {PersonnelApi} from "../../../api/PersonnelApi";
import {ItemMagnifier} from "./ItemMagnifier";
import _ from "lodash";
import Utils from "../../../Utils";
import {useTranslation} from "react-i18next";

const Comparator = (props) => {

    const [items, setItems] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState(false)
    const [right, setRight] = useState({});
    const [left, setLeft] = useState({});
    const [rightIndex, setRightIndex] = useState(0);
    const [leftIndex, setLeftIndex] = useState(0);
    const [showPath, setShowPath] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        PersonnelApi.getDicomsOfPatient(props.context.patient.patient.id, props.wound.id).then(r => {
            let items = props?.wound?.newClassif?.planimetries ? props.wound.newClassif.planimetries.concat(r.data) : r.data
            setItems(items)
            setItemsLoaded(true)
            if (items[0]) {
                setLeft(items[0])
                setRight(items[0])
            } else {
                setLeft({})
                setRight({})
            }
            setLeftIndex(0)
            setRightIndex(0)

            if (items[1]) {
                setRight(items[1])
                setRightIndex(1)
            }
        })
    }, [props.wound.id])

    const onChangeLeft = (event) => {
        setLeft(selectedValue(event.target.value))
        setLeftIndex(selectedIndex(event.target.value))
    }

    const onChangeRight = (event) => {
        setRight(selectedValue(event.target.value))
        setRightIndex(selectedIndex(event.target.value))
    }

    const selectedValue = (element) =>{
        return items.filter(d => {
                if (d.dicom !== undefined) {
                    return d.dicom.id === parseInt(element)
                } else {
                    return d.id === parseInt(element)
                }
            }
        )[0];
    }

    const selectedIndex = (element) =>{
        return items.map(d => {
            if (d.dicom !== undefined) {
                return d.dicom.id
            } else {
                return d.id
            }
        }).indexOf(parseInt(element));
    }

    const getGroupedItems = () =>{
        return _(items)
            .groupBy(x => x?.visitDetails?.visit?.id)
            .map((value, key) => ({visit: key, planimetries: value}))
            .value()
    }

    const select = (index, onChange) => {

        let itemsGrouped = getGroupedItems()

        let selectedValue = items[index].dicom !== undefined ? items[index].dicom.id : items[index].id;

        return <Input type="select" name="selectLeft" value={selectedValue} onChange={onChange}
                      className="mb-1 custom-select border-1">
            {itemsGrouped.map((g, index) => {
                    let personnel = g.planimetries[0]?.visitDetails?.personnel
                    let visit = Utils.getFormattedDate(g.planimetries[0]?.visitDetails?.visit?.visitDate, "YYYY-MM-DD HH:mm")
                    return <optgroup key={index}
                        label={personnel !== undefined ? personnel.degree + " " + personnel.firstname + " " + personnel.lastname + " (" + t("visitDateText") +": " + visit + ")" : t("currentVisit")}>
                        {g.planimetries.map(d =>
                            <option style={{backgroundColor: (d.dicom !== undefined ? 'white' : '#d2f8d2')}}
                                    key={d.dicom !== undefined ? d.dicom.id : d.id}
                                    value={d.dicom !== undefined ? d.dicom.id : d.id}>{Utils.getFormattedDate(d.modT) || Utils.getFormattedDate(d.dicom.modT)}</option>
                        )
                        }
                    </optgroup>
                }
            )}

        </Input>
    }


    if (!itemsLoaded) return ''

    if (items.length === 0) return <span className="fw700">{t("common.noData")}</span>

    return (
        <Container className="themed-container">
            <Row style={{justifyContent: "center"}}>

                <Col style={{maxWidth: "50%"}}>
                    {select(leftIndex, onChangeLeft)}
                    <ItemMagnifier data={left} side="l" showPath={showPath}/>
                </Col>

                <Col lg={1} style={{textAlign: "center", marginTop: "auto", marginBottom: "auto"}}>

                    <ButtonGroup vertical>
                        <Button color="success" outline={showPath} onClick={() => {
                            setShowPath(false)
                        }}><i className="far fa-search"/></Button>

                        <Button color="success" outline={!showPath} onClick={() => {
                            setShowPath(true)
                        }}><i className="fas fa-draw-polygon"/></Button>
                    </ButtonGroup>
                </Col>

                {items.length > 1 ? <Col>
                    {select(rightIndex, onChangeRight)}
                    <ItemMagnifier data={right} side="r" showPath={showPath}/>
                </Col> : null}

            </Row>
        </Container>
    )


}


export default connect(mapStorageToProps, mapStorageDispatchToProps)(Comparator)
