import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {Popover, PopoverBody} from "reactstrap";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";


class ButtonIcon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false
        }
    }

    static defaultProps = {
        buttonText: "",
        popoverPosition: "bottom"
    };

    render() {
        return <>
            <button style={{marginLeft: 10, marginRight: 5}}
                    type="button"
                    className={"btn-sm " + this.props.buttonType}
                    onClick={this.props.action && this.props.action(this.props.index)}
                    id={this.props.field + "-popover"}
                    onMouseOver={() => {
                        this.setState({popoverOpen: true})
                    }}
                    onMouseLeave={() => {
                        this.setState({popoverOpen: false})
                    }}
                    onTouchStart={() => {
                        this.setState({popoverOpen: true});
                        setTimeout(() => {
                            this.setState({popoverOpen: false})
                        }, 3000)
                    }}>{this.props.buttonText !== "" &&
            <span style={{marginRight: 5}}>{this.props.buttonText}</span>
            }
                <i className={this.props.icon}/>
            </button>
            {this.props.popover &&
            <Popover
                isOpen={this.state.popoverOpen}
                target={this.props.field + "-popover"}
                placement={this.props.popoverPosition}
                hideArrow={true}
            >
                <PopoverBody style={{fontSize: '0.875em'}}>
                    {this.props.popover}
                </PopoverBody>
            </Popover>
            }
        </>;
    }
}

ButtonIcon.propTypes = {
    colLg: PropTypes.number,
    action: PropTypes.func,
    buttonText: PropTypes.string,
    icon: PropTypes.string,
    field: PropTypes.string,
    buttonType: PropTypes.string,
    popoverPosition: PropTypes.string
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ButtonIcon)
