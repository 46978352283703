import React from 'react';
import ReactDOM from 'react-dom';
import {store} from "app/redux/redux";
import {Provider} from "react-redux";
import App from "app/app-main/App";
import 'assets/bootstrap/css/bootstrap.min.css';
import "assets/css/all.min.css";
import "assets/css/loader.css";
import "assets/css/ebok-admin.css";
import "assets/css/text.css";
import "assets/css/buttons.css";
import "react-widgets/styles.css"
import {register, unregister} from "./serviceWorker";
import "process/browser"


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

process.env.NODE_ENV === "development" ? unregister() : register()

