import {useTranslation} from "react-i18next";
import {lazy} from "react";
import ViewVisitsReports from "../app-views/authorized/admin/ViewVisitsReports";

const ViewAdminDashboard = lazy(() => import("../app-views/authorized/admin/ViewAdminDashboard"));
const ViewAdminAPKReleases = lazy(() => import("../app-views/authorized/admin/ViewAdminAPKReleases"));
const ViewAdminLoadPatient = lazy(() => import("../app-views/authorized/admin/ViewAdminLoadPatient"));
const ViewAdminPlanimetries = lazy(() => import("../app-views/authorized/admin/ViewAdminPlanimetries"));
const ViewAdminUsers = lazy(() => import("../app-views/authorized/admin/ViewAdminUsers"));
const ViewAdminConsent = lazy(() => import("../app-views/authorized/admin/consent/ViewAdminConsent"));
const ViewAdminSurveys = lazy(() => import("../app-views/authorized/admin/surveys/ViewAdminSurveys"));
const ViewAdminClinics = lazy(() => import("../app-views/authorized/admin/ViewAdminClinics"));

const useAdminRoutes = (props) => {
    const {t} = useTranslation()
    return [
        {
            key: 'dashboard',
            exact: true,
            path: "/admin",
            has: ['admin_dashboard'],
            component: ViewAdminDashboard,
            sidebar: true,
            label: t("sidebar.superadmin.dashboard"),
            icon: 'fas fa-tachometer-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'apk',
            exact: true,
            path: "/apk",
            has: ['admin_mobile'],
            component: ViewAdminAPKReleases,
            sidebar: true,
            label: t("sidebar.superadmin.mobileApplications"),
            icon: 'fas fa-mobile-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'loadUsers',
            exact: true,
            path: "/upload_users",
            has: ['admin_clinics'],
            component: ViewAdminLoadPatient,
            sidebar: true,
            label: t("sidebar.superadmin.loadPatients"),
            icon: 'fas fa-upload',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'planimetries',
            exact: true,
            path: "/planimetries",
            has: ['admin_reports'],
            component: ViewAdminPlanimetries,
            sidebar: true,
            label: t("sidebar.superadmin.planimetryReport"),
            icon: 'far fa-file-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: props?.featureFlags?.planimetry === false ? props?.featureFlags?.planimetry : true
        },
        {
            key: 'users',
            exact: true,
            path: "/users",
            has: ['admin_users'],
            component: ViewAdminUsers,
            sidebar: true,
            label: t("sidebar.superadmin.users"),
            icon: 'fas fa-users',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'consent',
            exact: true,
            path: "/consent",
            has: ['admin_consents'],
            component: ViewAdminConsent,
            sidebar: true,
            label: t("sidebar.superadmin.consent"),
            icon: 'fas fa-file-check',
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        // {
        //     key: 'surveys',
        //     exact: true,
        //     path: "/surveys",
        //     has: ['admin_surveys'],
        //     component: ViewAdminSurveys,
        //     sidebar: true,
        //     label: t("sidebar.superadmin.survey"),
        //     icon: 'fas fa-file',
        //     shouldSensitiveDataVisible: false,
        //     showOnMobile: true,
        //     featureFlagsShow: true
        // },
        {
            key: 'clinics',
            exact: true,
            path: "/clinics",
            has: ['admin_clinics'],
            component: ViewAdminClinics,
            sidebar: true,
            label: t("sidebar.superadmin.facilities"),
            icon: "far fa-hospitals",
            shouldSensitiveDataVisible: false,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'reports',
            exact: true,
            path: "/reports",
            has: ['admin_reports'],
            component: ViewVisitsReports,
            sidebar: true,
            label: t("sidebar.reports"),
            icon: 'far fa-file-alt',
            shouldSensitiveDataVisible: false,
            showOnMobile: false,
            featureFlagsShow: props?.featureFlags?.visitsReports === true
        }
    ];

}
export default useAdminRoutes
