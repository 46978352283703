import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";


class FormButton extends Component {
    render() {
        return <button
            className={"btn btn-" + this.props.color + " mt-" + this.props.mt + " mr-" + this.props.mr + " mb-" + this.props.mb + " ml-" + this.props.ml +  " " + this.props.className}
            onClick={this.props.action}
            disabled={this.props.disabled}>{this.props.title}
        </button>
    }
}

FormButton.defaultProps = {
    color: "success",
    className: "",
    disabled: false,
    mt: 4,
    mr: 4,
    mb: 4,
    ml: 0
};

FormButton.propTypes = {
    action: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    mt: PropTypes.number,
    mr: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormButton)
