import React, {Component} from "react";
import moment from "moment";
import {PersonnelApi} from "../api/PersonnelApi";

export default class AppFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      version: undefined,
    }
  }
    
  componentDidMount() {
    PersonnelApi.getVersionApp().then((json) => {
      this.setState({version: json.data})
    })
  }

  render() {
    return <>
      <footer className="bg-white sticky-footer">
        <div className="container my-auto">
          <div className="text-white small text-center">
            <span className="ml-2">[{window.tag}]</span> <span>[{this.state.version}]</span>
          </div>
          <div className="text-center my-auto copyright">
            <span>Copyright © ITMatter {moment().format('Y')}</span>
          </div>
        </div>
      </footer>
    </>
  }
}

