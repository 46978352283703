import React from 'react';
import {AdminReportsApi} from "../../../api/AdminReportsApi";
import {Button} from "reactstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import Utils from "../../../Utils";
import {ajaxStop, store} from "../../../redux/redux";
import FormHeaderExtended from "../../../app-components/common/Form/FormHeaderExtended";
import FormDatePicker from "../../../app-components/common/Form/FormDatePicker";
import {FormSection} from "../../../app-components/common/Form/FormSection";
import FormWrapper from "../../../app-components/common/Form/FormWrapper";
import {FormRow} from "../../../app-components/common/Form/FormRow";
import {Translation} from "react-i18next";
import FormInput from "../../../app-components/common/Form/FormInput";
import FormButton from "../../../app-components/common/Form/FormButton";
import FormCol from "../../../app-components/common/Form/FormCol";

const FORM_ID_XLS = "reports-visits-xls"
const FORM_ID_PDF = "reports-visits-pdf"

const ViewVisitsReports = (props) => {

    const getAllVisits = () => {

        const from = Utils.dateToStr(props.form[FORM_ID_XLS]?.from);
        const to = Utils.dateToStr(props.form[FORM_ID_XLS]?.to);

        AdminReportsApi.getAllVisitsReport(props.user.clinic.id, from, to)
            .then(r => r.blob())
            .then(blob => {
                Utils.downloadFileBytes(blob, "report.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                store.dispatch(ajaxStop());
            })
    }


    const getPersonnelVisits = () => {

        const from = Utils.dateToStr(props.form[FORM_ID_PDF]?.from);
        const to = Utils.dateToStr(props.form[FORM_ID_PDF]?.to);

        AdminReportsApi.getPersonnelVisitsReport(from, to)
            .then(r => r.blob())
            .then(blob => {
                Utils.downloadFileBytes(blob, "report.pdf", "application/pdf");
                store.dispatch(ajaxStop());
            })
    }

    return <Translation>
        {
            (t, {i18n}) => <>

                {(props.user?.authorities?.includes('receptionist') || props.user?.authorities?.includes('admin_reports')) &&
                    <FormWrapper formId={FORM_ID_XLS}>
                        <FormHeaderExtended>
                            <div className="d-flex align-items-center">
                                <div>{t("Raport zbiorczy wizyt i pacjentów")}</div>
                            </div>
                        </FormHeaderExtended>
                        <FormSection>
                            <FormRow>
                                <FormDatePicker required label={t("Data od")} field="from" time={false}/>
                                <FormDatePicker required label={t("Data do")} field="to" time={false}/>
                            </FormRow>
                            <FormRow>
                                <FormButton action={() => getAllVisits()} title="Pobierz raport XLSX"/>
                            </FormRow>
                        </FormSection>
                    </FormWrapper>
                }

                {props.user?.authorities?.includes('md') &&
                <FormWrapper formId={FORM_ID_PDF}>
                    <FormHeaderExtended>
                        <div className="d-flex align-items-center">
                            <div>{t("Raport przeprowadzonych wizyt")}</div>
                        </div>
                    </FormHeaderExtended>
                    <FormSection>
                        <FormRow>
                            <FormDatePicker required label={t("Data od")} field="from" time={false}/>
                            <FormDatePicker required label={t("Data do")} field="to" time={false}/>
                        </FormRow>
                        <FormRow>
                            <FormButton action={() => getPersonnelVisits()} title="Pobierz raport PDF"/>
                        </FormRow>
                    </FormSection>
                </FormWrapper>
                }

            </>
        }
    </Translation>

}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(ViewVisitsReports))