const initQuestForm = {
    covidPatientFormActive: false,
    covidPersonnelFormActive: false
};


export const setCovidPatientFormActive = (active) => ({
    type: 'SET_COVID_PATIENT_FORM_ACTIVE',
    active
});


export const setCovidPersonnelFormActive = (active) => ({
    type: 'SET_COVID_PERSONNEL_FORM_ACTIVE',
    active
});

export const questForm = (state = initQuestForm, action) => {
    
    switch (action.type) {
        case 'SET_COVID_PATIENT_FORM_ACTIVE':
            return {
                ...state,
                covidPatientFormActive: action.active
            };

        case 'SET_COVID_PERSONNEL_FORM_ACTIVE':
            return {
                ...state,
                covidPersonnelFormActive: action.active
            };
            
        default:
            return state;
    }
};
