import React from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../../../redux/mappers";
import {withRouter} from "react-router-dom";
import {FormRow} from "../../../../common/Form/FormRow";
import {FormRowCol} from "../../../../common/Form/FormRowCol";
import FormRowField from "../../../../common/Form/FormRowField";
import ImageSecure from "../../../../common/theme/ImageSecure";
import _ from 'lodash'
import FormRowText from "../../../../common/Form/FormRowText";
import {PersonnelApi} from "../../../../../api/PersonnelApi";
import {useTranslation} from "react-i18next";

export const NewVisitWoundClassificationHistory = (props) => {

    const {t} = useTranslation()

    const openTab = (dicomId) => {
        {
            let newTab = window.open();
            PersonnelApi.getImages(window.var.API_HOST + "/api/v1/personnel/dicom/image/" + dicomId).then(r => {
                newTab.document.body.innerHTML = '<img src="data:image/png;base64,' + r.data.base64 + '" width="600px" height="auto" alt="dokument">';
            })
        }
    }

    return props.planimetries.length === 0 ?
        <FormRowText>{t("noPlanimetries")}</FormRowText> :
        _.map(props.planimetries, (planimetry, index) => {
                return <div key={index}><FormRow className="border-bottom">
                    <FormRowCol col={4}>
                        <div className="pointer"
                             onClick={() => openTab(planimetry.dicomId)} title={t("openPhotos")}>
                            <ImageSecure
                                imgSrc={window.var.API_HOST + "/api/v1/personnel/dicom/thumbnail/" + planimetry.dicomId}
                                style={{
                                    minWidth: 100,
                                    minHeight: 100,
                                    width: 100,
                                    height: 100,
                                    padding: 5,
                                    textAlign: "right"
                                }}/>
                        </div>
                    </FormRowCol>
                    <FormRowCol col={8}>
                        <FormRow>
                            <FormRowField formGroupClass="mb-0" label={t("area")} colLg={12} colLabel={6}
                                          colValue={6}>{planimetry.parameters.summary.area} cm<sup>2</sup></FormRowField>
                            <FormRowField formGroupClass="mb-0" label={t("diagonal")} colLg={12}
                                          colLabel={6}
                                          colValue={6}>{planimetry.parameters.summary.diagonal} cm</FormRowField>
                            <FormRowField formGroupClass="mb-0" label={t("perimeter")} colLg={12}
                                          colLabel={6}
                                          colValue={6}>{planimetry.parameters.summary.perimeter} cm</FormRowField>
                        </FormRow>
                    </FormRowCol>
                </FormRow></div>
            }
        )


}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(NewVisitWoundClassificationHistory))
