import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import DtPagination from "./DtPagination";
import DtSort from "./DtSort";
import DtFilters from "./DtFilters";
import {Button, Card, CardBody, CardHeader, Collapse, Row} from "reactstrap";
import Col from "react-bootstrap/Col";
import {Translation} from 'react-i18next';


export const dataTableDefaultProps = {
    labels: {
        pageSize: "pageSize",
        page: 'page',
        of: 'of',
        showFilters: 'showFilters',
        hideFilters: 'hideFilters',
        clear: 'clear',
        search: 'search'
    },
    defaultPage: 1,
    defaultPageSize: 20,
    defaultFilters: {},
    defaultSort: undefined,
    unwrapFilters: false
};

class DataTable extends Component {

    static defaultProps = dataTableDefaultProps

    constructor(props) {
        super(props);
        this.props.setDataTableParams(this.copyDefaultCriteria(), this.props.id);
        this.getData = this.getData.bind(this);
        this.getData();

        this.state = {
            collapse: null
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.print !== this.props.print && this.props.print) {
            this.props.setDataTableParams({
                page: 1,
                pageSize: 1000000,
                defaultFilters: {},
                defaultSort: undefined
            }, this.props.id);
        }
        if (prevProps.print !== this.props.print && !this.props.print) {
            this.props.setDataTableParams(this.copyDefaultCriteria(), this.props.id);
        }
    }

    componentWillUnmount() {
        this.props.unsetDataTable(this.props.id)
    }

    copyDefaultCriteria() {
        return JSON.parse(
            JSON.stringify({
                page: this.props.defaultPage,
                pageSize: this.props.defaultPageSize,
                filters: this.props.defaultFilters,
                sort: this.props.defaultSort
            })
        )
    }

    dataTable = () => {
        return this.props.dataTable[this.props.id]
    };

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    getData() {
        this.props.api(this.dataTableParams() ? this.dataTableParams() : this.copyDefaultCriteria(), this.props.pathVariable).then(r => {
            this.props.setDataTable(r.data, this.props.id);
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props?.dataTable[this.props.id]?._rand !== nextProps.dataTable[this.props.id]?._rand) {
            this.getData()
        }
    }

    printHeaderWeb() {
        return <tr>
            {this.props.cols.map((c, i) => {
                return <th key={i}
                           className={(c.thClassName ? c.thClassName : 'text-center')}>{c.headerFormatter ? c.headerFormatter(c.header, c, this) : c.header}
                    <DtSort
                        id={this.props.id} column={c} dt={this}/></th>
            })}
        </tr>;
    }

    printBodyWeb() {
        return (this.dataTable().total > 0) ? this.dataTable().rows.map((r, i) => {
            return <React.Fragment key={i}>
                <tr key={i} onClick={this.props.trOnClick ? this.props.trOnClick(r) : undefined}
                    style={this.props.trActive ? (this.props.trActive(r) ? {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer'
                    } : {cursor: 'pointer'}) : r[this.props.extraBackgroundColorExtractor] ? {background: "#feedea"} : undefined}>
                    {this.props.cols.map((c, ci) => {
                        let value = c.field ? c.field.split('.').reduce((res, curr) => res[curr], r) : '';
                        return <td className={c.tdClassName ? c.tdClassName : 'text-center txt-black-small'}
                                   key={ci}
                                   style={{verticalAlign: "middle"}}>{c.formatter ? c.formatter(value, r, c, this) : value}</td>
                    })}
                </tr>
                {this.props.trActive && this.props.trActive(r) &&
                    <tr key={'c' + i} style={{backgroundColor: '#ffffff'}}>
                        <td colSpan={this.props.cols.length}>{this.props.trActiveBody(r)}</td>
                    </tr>}
            </React.Fragment>
        }) : <Translation>
            {(t, {i18n}) => <tr>
                <td className="text-center" colSpan={this.props.cols.length}>{t("common.noData")}</td>
            </tr>}
        </Translation>
    }

    printFilters() {
        return (this.props.filters && this.props.filters.length) > 0 ?
            <DtFilters {...this.props} getData={this.getData}/> : '';
    }

    printPagination() {
        if (this.props.pageable === false) {
            return '';
        }
        return this.dataTable().total > 0 ? <DtPagination {...this.props} getData={this.getData}/> : '';
    }

    render() {
        return (this.dataTableParams() && this.dataTable()) ? <div style={{width: "100%"}}>
            {this.printFilters()}
            {window.innerWidth >= 991 ? this.renderForWeb() : this.renderForMobile()}
            {this.printPagination()}
        </div> : ''
    }

    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({collapse: this.state.collapse === Number(event) ? null : Number(event)});
    }

    renderForMobile() {
        return <div className="container" style={{paddingTop: 10, paddingBottom: 10}}>

            {this.dataTable().rows.map((row, index) => {
                return (
                    <Card style={{marginBottom: '2px'}} key={index}>
                        <CardHeader onClick={this.toggle.bind(this)}>
                            <Row>
                                <Col xs={1} sm={1} md={1}>
                                    <Button color="default" onClick={this.toggle.bind(this)} data-event={index}
                                            style={{padding: 0}}>
                                        <i className={"fas " + (this.state.collapse === index ? "fa-chevron-circle-down" : "fa-chevron-circle-right")}
                                           data-event={index}/>
                                    </Button>
                                </Col>
                                {this.props.cols
                                    .filter((c) => c.mobileHeader)
                                    .map((c, i) => {
                                        let value = c.field ? c.field.split('.').reduce((res, curr) => res[curr], row) : '';
                                        return <Col key={i}>
                                            <strong>{c.formatter ? c.formatter(value, row) : value}</strong>
                                        </Col>
                                    })
                                }
                            </Row>

                        </CardHeader>
                        <Collapse isOpen={this.state.collapse === index}>
                            <CardBody>
                                {this.props.cols
                                    .filter((c, i) => !c.mobileHeader)
                                    .map((c, i) => {
                                        let value = c.field ? c.field.split('.').reduce((res, curr) => res[curr], row) : '';
                                        return <Col
                                            key={i}><strong>{c.header}:</strong> {c.formatter ? c.formatter(value, row) : value}
                                        </Col>
                                    })
                                }
                            </CardBody>
                        </Collapse>
                    </Card>
                )
            })}

        </div>
    }

    renderForWeb() {
        return <div className="table-responsive" style={this.props.webDataTableStyle}>
            <table className="table table-hover message-table">
                <thead>
                {this.printHeaderWeb()}
                </thead>
                <tbody>
                {this.printBodyWeb()}
                </tbody>
            </table>
        </div>;
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DataTable);
