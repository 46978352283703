import {useResizeDetector} from "react-resize-detector";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {GlassMagnifier} from "react-image-magnifiers";
import {securedImg} from "../../../api/Api";
import {Card, CardBody, CardText} from "reactstrap";
import Utils from "../../../Utils";
import {useTranslation} from "react-i18next";

export const ItemMagnifier = (props) => {

    const {width, height, ref} = useResizeDetector();
    const [loading, setLoading] = useState(true)
    const {t} = useTranslation()

    useEffect(() => {
        setLoading(true)
    }, [props.data])

    useEffect(() => {

        const canvas = document.getElementById(`path-${props.side}`);

        if (canvas && canvas.getContext && props.showPath) {
            let f = width > height ? (width / 800) : (height / 800)
            let paths = props.data?.parameters?.parameters?.paths || props.data?.parameters?.paths
            const ctx = canvas.getContext('2d');

            ctx.resetTransform()
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.scale(f, f)

            paths.forEach((path) => {
                if (path.points.length < 6 || !path.closed) {
                    return
                }

                const points = _.chunk(path.points, 2).map(p => ({x: p[0], y: p[1]}))

                ctx.beginPath();
                // console.log("points", points)
                const start = points.splice(0, 1)[0]
                ctx.moveTo(start.x, start.y);
                points.map((p) => {
                    // ctx.font = "12px Arial";
                    // ctx.fillText(`(${p.x}, ${p.y})`, p.x, p.y);
                    // console.log("map", p.x, p.y)
                    ctx.lineTo(p.x, p.y);
                })
                ctx.lineTo(start.x, start.y);
                ctx.fillStyle = 'rgba(255, 165, 0, 0.2)';
                ctx.fill();
                ctx.closePath();
                ctx.stroke();

            })
        }
    }, [props.data, props.showPath, width, height])


    const renderImg = (url) => {
        return url ? <div ref={ref}>

            {props.showPath && <canvas id={`path-${props.side}`} style={{
                background: "rgba(255, 255, 255, 0.1)",
                position: "absolute",
                zIndex: 99999,
                visibility: loading ? 'hidden' : 'visible'
            }} width={width || 0} height={height || 0}/>}

            {loading && <div style={{padding: 30}}>{t("waitForPhoto")}</div>}

            <GlassMagnifier
                imageSrc={securedImg(window.var.API_HOST + url + "/media")}
                onImageLoad={(a) => setLoading(false)}
                magnifierSize="50%"
                style={{visibility: loading ? 'hidden' : 'visible'}}
            />

        </div> : <></>
    }


    return (props.data.url || props.data.imageUrl) ? (
            <Card>
                <CardBody>

                    {renderImg(props.data.url)}
                    {renderImg(props.data.imageUrl)}

                    <CardText>
                        <br/>
                        {t("date")}: {Utils.getFormattedDate(props.data.modT) || Utils.getFormattedDate(props.data.dicom.modT)}<br/>
                        {t("area")}: {props.data?.parameters?.summary?.area || props.data?.parameters?.parameters?.summary?.area} cm<sup>2</sup><br/>
                        {t("diagonal")}: {props.data?.parameters?.summary?.diagonal || props.data?.parameters?.parameters?.summary?.diagonal} cm<br/>
                        {t("perimeter")}: {props.data?.parameters?.summary?.perimeter || props.data?.parameters?.parameters?.summary?.perimeter} cm<br/>
                    </CardText>
                </CardBody>
            </Card>
        ) :
        <></>
}
