import _ from 'lodash'
import i18n from 'i18next';
import {useTranslation} from "react-i18next";

export const useSurveyTypes = () => {
    const {t} = useTranslation()
    return [{type: 1, title: t("survey.satisfaction")},
            {type: 2, title: t("survey.qualityAssessmentOfCLOServices")}]
}

const Dicts = {

    addressType: function () {
        return [
            {label: i18n.t('address.residence'), value: "zamieszkania"},
            {label: i18n.t('address.registered'), value: "zameldowania"},
            {label: i18n.t('address.correspondence'), value: "korespondencyjny"}
        ]
    },

    personnelRole: function () {
        return [
            {
                value: 'lekarz',
                label: i18n.t('doctor')
            },
            {
                value: 'pielegniarka',
                label: i18n.t('nurse')
            }
        ]
    },

    gender: function () {
        return [
            {
                value: 'kobieta',
                label: i18n.t('gender.women')
            },
            {
                value: 'mezczyzna',
                label: i18n.t('gender.man')
            },
            {
                value: 'nieokreslona',
                label: i18n.t('gender.undefinedGender')
            }
        ]
    },

    patientPortal: function () {
        return [
            {
                value: true,
                label: i18n.t('common.yes')
            },
            {
                value: false,
                label: i18n.t('common.no')
            }
        ]
    },

    visitConfirmation: function () {
        return [
            {
                value: 'potwierdzona',
                label: i18n.t('visitConfirmation.confirmed')
            },
            {
                value: 'niepotwierdzona',
                label: i18n.t('visitConfirmation.unconfirmed')
            },
            {
                value: 'odrzucona',
                label: i18n.t('visitConfirmation.rejected')
            },
            {
                value: 'anulowana',
                label: i18n.t('visitConfirmation.canceled')
            },
            {
                value: 'niezaplanowana',
                label: i18n.t('visitConfirmation.unplanned')
            },
        ]
    },

    visualClassification: function () {
        return [
            {
                value: 'surowiczy',
                label: i18n.t('visualClassif.serous')
            },
            {
                value: 'surowiczny_krwisty',
                label: i18n.t('visualClassif.serousBloody')
            },
            {
                value: 'krwisty',
                label: i18n.t('visualClassif.bloody')
            },
            {
                value: 'surowiczo_ropny',
                label: i18n.t('visualClassif.serousPurulent')
            },
            {
                value: 'wloknisty',
                label: i18n.t('visualClassif.fibrous')
            },
            {
                value: 'ropny',
                label: i18n.t('visualClassif.purulent')
            },
            {
                value: 'krwio_ropny',
                label: i18n.t('visualClassif.bloodPurulent')
            },
            {
                value: 'krwotoczny',
                label: i18n.t('visualClassif.hemorrhagic')
            }
        ]
    },

    smellClassification: function () {
        return [
            {
                value: 'zapach_wyczuwalny_0',
                label: i18n.t('smellClassif.noticable', {step: "0"})
            },
            {
                value: 'zapach_wyczuwalny_1',
                label: i18n.t('smellClassif.noticable', {step: "1"})
            },
            {
                value: 'zapach_wyczuwalny_2',
                label: i18n.t('smellClassif.noticable', {step: "2"})
            },
            {
                value: 'zapach_wyczuwalny_3',
                label: i18n.t('smellClassif.noticable', {step: "3"})
            },
            {
                value: 'zapach_wyczuwalny_4',
                label: i18n.t('smellClassif.noticable', {step: "4"})
            },
            {
                value: 'brak_zapachu_5',
                label: i18n.t('smellClassif.notNoticable')
            }
        ]
    },

    pedisClassification: function () {
        return [
            {
                value: 'stopien_I',
                label: i18n.t('pedisClassif.degree', {degree: "I"})
            },
            {
                value: 'stopien_II',
                label: i18n.t('pedisClassif.degree', {degree: "II"})
            },
            {
                value: 'stopien_III',
                label: i18n.t('pedisClassif.degree', {degree: "III"})
            },
            {
                value: 'stopien_IV',
                label: i18n.t('pedisClassif.degree', {degree: "IV"})
            }
        ]
    },

    texasDepthClassification: function () {
        return [
            {
                value: 'stopien_0',
                label: i18n.t('texasDepthClassif.degree', {degree: "0"})
            },
            {
                value: 'stopien_1',
                label: i18n.t('texasDepthClassif.degree', {degree: "1"})
            },
            {
                value: 'stopien_2',
                label: i18n.t('texasDepthClassif.degree', {degree: "2"})
            },
            {
                value: 'stopien_3',
                label: i18n.t('texasDepthClassif.degree', {degree: "3"})
            }
        ]
    },


    notesType: function () {
        return [
            {
                value: 'przed_planimetria',
                label: i18n.t('notesType.beforePlanimetry')
            },
            {
                value: 'po_planimetri',
                label: i18n.t('notesType.afterPlanimetry')
            }
        ]
    },


    documentType: function () {
        return [
            {
                value: 'lekarza',
                label: i18n.t('documentType.doctors')
            },
            {
                value: 'pielegniarki',
                label: i18n.t('documentType.nurse')
            }
        ]
    },


    texasInfectionClassification: function () {
        return [
            {
                value: 'stopien_A',
                label: i18n.t('texasInfectionClassif.degree', {degree: "A"})
            },
            {
                value: 'stopien_B',
                label: i18n.t('texasInfectionClassif.degree', {degree: "B"})
            },
            {
                value: 'stopien_C',
                label: i18n.t('texasInfectionClassif.degree', {degree: "C"})
            },
            {
                value: 'stopien_D',
                label: i18n.t('texasInfectionClassif.degree', {degree: "D"})
            }
        ]
    },

    visitType: function () {
        return [
            {
                value: 'wizyta_lekarska_zdalna',
                label: i18n.t('visitType.remoteMedical')
            },
            {
                value: 'wizyta_pielegniarska_zdalna',
                label: i18n.t('visitType.remoteNurse')
            },
            {
                value: 'wizyta_lekarska_miejscowa',
                label: i18n.t('visitType.localMedical')
            },
            {
                value: 'wizyta_pielegniarska_miejscowa',
                label: i18n.t('visitType.localNurse')
            },
            {
                value: 'wizyta_kontrolna',
                label: i18n.t('visitType.controlVisit')
            }
        ]
    },

    plannedVisit: function () {
        return [
            {
                value: 'planowana',
                label: i18n.t('planned')
            },
            {
                value: 'nieplanowana',
                label: i18n.t('unplanned')
            }
        ]
    },

    colorClassification: function () {
        return [
            {
                value: 'czarny',
                label: i18n.t('colors.black')
            },
            {
                value: 'zolty',
                label: i18n.t('colors.yellow')
            },
            {
                value: 'czerwony',
                label: i18n.t('colors.red')
            },
            {
                value: 'rozowy',
                label: i18n.t('colors.pink')
            }
        ]
    },

    biofilmClassification: function () {
        return [
            {value: 'brak', label: i18n.t('emptyBiofilm')},
            {value: 'I', label: '+'},
            {value: 'II', label: '++'},
            {value: 'III', label: '+++'}
        ]
    },

    allowToTrade: function () {
        return [
            {
                value: 'dopuszczony',
                label: i18n.t('tradeClassif.admitted')
            },
            {
                value: 'niedopuszczony',
                label: i18n.t('tradeClassif.notAdmitted')
            },
            {
                value: 'koniec_dopuszczenia_do_obrotu',
                label: i18n.t('tradeClassif.endOfAdmit')
            }
        ]
    },

    woundEtiology: function () {
        return [
            {value: 'zylna', label: i18n.t('woundEtiologyClassif.venous')},
            {value: 'tetnicza', label: i18n.t('woundEtiologyClassif.arterial')},
            {value: 'mieszana', label: i18n.t('woundEtiologyClassif.mixed')},
            {value: 'nowotworcza', label: i18n.t('woundEtiologyClassif.neoplastic')},
            {value: 'odlezynowaIstopnia', label: i18n.t('woundEtiologyClassif.bedsoresDegree', {degree: "I"})},
            {value: 'odlezynowaIIstopnia', label: i18n.t('woundEtiologyClassif.bedsoresDegree', {degree: "II"})},
            {value: 'odlezynowaIIIstopnia', label: i18n.t('woundEtiologyClassif.bedsoresDegree', {degree: "III"})},
            {value: 'odlezynowaIVstopnia', label: i18n.t('woundEtiologyClassif.bedsoresDegree', {degree: "IV"})},
            {value: 'odlezynowaVstopnia', label: i18n.t('woundEtiologyClassif.bedsoresDegree', {degree: "V"})},
            {value: 'pooperacyjna', label: i18n.t('woundEtiologyClassif.postoperative')},
            {value: 'oparzeniowa', label: i18n.t('woundEtiologyClassif.burns')},
            {value: 'pourazowa', label: i18n.t('woundEtiologyClassif.traumatic')},
            {value: 'cukrzycowa', label: i18n.t('woundEtiologyClassif.diabetic')}
        ]
    },

    stepClassification: function () {
        return [
            {value: 'IA_1PL', label: i18n.t('stepClassif', {stepName: "IA - 1PL"})},
            {value: 'IA_2PL', label: i18n.t('stepClassif', {stepName: "IA - 2PL"})},
            {value: 'IA_3PL', label: i18n.t('stepClassif', {stepName: "IA - 3PL"})},
            {value: 'IA_4PL', label: i18n.t('stepClassif', {stepName: "IA - 4PL"})},
            {value: 'IA_1K', label: i18n.t('stepClassif', {stepName: "IA - 1K"})},
            {value: 'IB_1PL', label: i18n.t('stepClassif', {stepName: "IB - 1PL"})},
            {value: 'IB_2PL', label: i18n.t('stepClassif', {stepName: "IB - 2PL"})},
            {value: 'IB_3PL', label: i18n.t('stepClassif', {stepName: "IB - 3PL"})},
            {value: 'IB_1K', label: i18n.t('stepClassif', {stepName: "IB - 1K"})},
            {value: 'IC_1PL', label: i18n.t('stepClassif', {stepName: "IC - 1PL"})},
            {value: 'IC_2PL', label: i18n.t('stepClassif', {stepName: "IC - 2PL"})},
            {value: 'IC_3PL', label: i18n.t('stepClassif', {stepName: "IC - 3PL"})},
            {value: 'IC_4PL', label: i18n.t('stepClassif', {stepName: "IC - 4PL"})},
            {value: 'IC_1K', label: i18n.t('stepClassif', {stepName: "IC - 1K"})},
            {value: 'ID', label: i18n.t('stepClassif', {stepName: "ID"})},
            {value: 'II', label: i18n.t('stepClassif', {stepName: "II"})},
            {value: 'III_1P', label: i18n.t('stepClassif', {stepName: "III - 1P"})},
            {value: 'III_2P', label: i18n.t('stepClassif', {stepName: "III - 2P"})},
            {value: 'III_3P', label: i18n.t('stepClassif', {stepName: "III - 3P"})},
            {value: 'III_4P', label: i18n.t('stepClassif', {stepName: "III - 4P"})},
            {value: 'III_1K', label: i18n.t('stepClassif', {stepName: "III - 1K"})}
        ]
    },

    mannequin: function () {
        return [
            {value: "brzuch-lewo", label: i18n.t('mannequin.bellyLeft')},
            {value: "brzuch-prawo", label: i18n.t('mannequin.bellyRight')},
            {value: "glowa-przod", label: i18n.t('mannequin.headFront')},
            {value: "glowa-tyl", label: i18n.t('mannequin.headBack')},
            {value: "golen-przod-lewo", label: i18n.t('mannequin.leftShinFront')},
            {value: "golen-przod-prawo", label: i18n.t('mannequin.rightShinFront')},
            {value: "golen-tyl-lewo", label: i18n.t('mannequin.leftShinBack')},
            {value: "golen-tyl-prawo", label: i18n.t('mannequin.rightShinBack')},
            {value: "grzbiet-ledzwie-lewo", label: i18n.t('mannequin.backLoinLeftSide')},
            {value: "grzbiet-ledzwie-prawo", label: i18n.t('mannequin.backLoinRightSide')},
            {value: "grzbiet-lopatka-lewo", label: i18n.t('mannequin.leftScapulaRidge')},
            {value: "grzbiet-lopatka-prawo", label: i18n.t('mannequin.rightScapulaRidge')},
            {value: "klatka-piersiowa-lewo", label: i18n.t('mannequin.leftChest')},
            {value: "klatka-piersiowa-prawo", label: i18n.t('mannequin.rightChest')},
            {value: "kolano-lewo", label: i18n.t('mannequin.leftKnee')},
            {value: "kolano-prawo", label: i18n.t('mannequin.rightKnee')},
            {value: "lokiec-lewo", label: i18n.t('mannequin.leftElbow')},
            {value: "lokiec-prawo", label: i18n.t('mannequin.rightElbow')},
            {value: "posladek-lewo", label: i18n.t('mannequin.leftButtock')},
            {value: "posladek-prawo", label: i18n.t('mannequin.rightButtock')},
            {value: "przedramie-przod-lewo", label: i18n.t('mannequin.forearmLeftFront')},
            {value: "przedramie-przod-prawo", label: i18n.t('mannequin.forearmRightFront')},
            {value: "przedramie-tyl-lewo", label: i18n.t('mannequin.forearmLeftBack')},
            {value: "przedramie-tyl-prawo", label: i18n.t('mannequin.forearmRightBack')},
            {value: "ramie-przod-lewo", label: i18n.t('mannequin.leftArmFront')},
            {value: "ramie-przod-prawo", label: i18n.t('mannequin.rightArmFront')},
            {value: "ramie-tyl-lewo", label: i18n.t('mannequin.leftArmRear')},
            {value: "ramie-tyl-prawo", label: i18n.t('mannequin.rightArmRear')},
            {value: "reka-przod-lewo", label: i18n.t('mannequin.leftHandInside')},
            {value: "reka-przod-prawo", label: i18n.t('mannequin.rightHandInside')},
            {value: "reka-tyl-lewo", label: i18n.t('mannequin.leftHandOuterSide')},
            {value: "reka-tyl-prawo", label: i18n.t('mannequin.rightHandOuterSide')},
            {value: "stopa-przod-lewo", label: i18n.t('mannequin.leftFootForward')},
            {value: "stopa-przod-prawo", label: i18n.t('mannequin.rightFootForward')},
            {value: "stopa-tyl-lewo", label: i18n.t('mannequin.leftFootBack')},
            {value: "stopa-tyl-prawo", label: i18n.t('mannequin.rightFootBack')},
            {value: "szyja-przod", label: i18n.t('mannequin.neckFront')},
            {value: "szyja-tyl", label: i18n.t('mannequin.neckBack')},
            {value: "udo-przod-lewo", label: i18n.t('mannequin.leftThighFront')},
            {value: "udo-przod-prawo", label: i18n.t('mannequin.rightThighFront')},
            {value: "udo-tyl-lewo", label: i18n.t('mannequin.leftThighBack')},
            {value: "udo-tyl-prawo", label: i18n.t('mannequin.rightThighBack')},
        ]
    },

    applicationType: function () {
        return [
            {
                value: 'mobile_personnel',
                label: i18n.t('applicationType.personnel')
            },
            {
                value: 'mobile_patient',
                label: i18n.t('applicationType.patient')
            }
        ]
    },

    booleanType: function () {
        return [
            {
                value: true,
                label: i18n.t('common.yes')
            },
            {
                value: false,
                label: i18n.t('common.no')
            }
        ]
    },
    wrongEmailOrPassword: function () {
        return i18n.t('wrongEmailOrPassword')
    },

    languageList: function () {
        return [
        {
             flag: "pl",
             code: "pl",
             label: i18n.t('language.polish')
         },
         {
             flag: "us",
             code: 'en',
             label: i18n.t('language.english')
         },
        {
             flag: "ua",
             code: "ua",
             label: i18n.t('language.ukrainian')
         }
       ]
    },

    languageOriginalList: function () {
        return [
        {
             flag: "pl",
             code: "pl",
             moment: "pl",
             label: "Polski"
         },
         {
             flag: "us",
             code: 'en',
             moment: "en",
             label: "English"
         },
        {
             flag: "ua",
             code: "ua",
             moment: "uk",
             label: "Український"
         }
       ]
    }

};

export default Dicts;
