import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import Image from "react-bootstrap/Image";
import {PersonnelApi} from "../../../api/PersonnelApi";


class ImageSecure extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imgSrc: null
        };
    }

    componentDidMount() {
        PersonnelApi.getImages(this.props.imgSrc).then(r => {
            this.setState({imgSrc: r.data.base64});
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.imgSrc !== prevProps.imgSrc) {
            PersonnelApi.getImages(this.props.imgSrc).then(r => {
                this.setState({imgSrc: r.data.base64});
            })
        }
    }


    render() {
        return <Image className={this.props.className} src={`data:image/jpeg;base64,${this.state.imgSrc}`}
                      style={this.props.style}
        />
    }
}

ImageSecure.propTypes = {
    imgSrc: PropTypes.string
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ImageSecure)