import React, {Component} from "react";
import * as PropTypes from "prop-types";

export class FormRowCol extends Component {
    render() {
        return <div className={"col-" + this.props.col + " " + this.props.className}
        style={this.props.style}>
            {this.props.children}
        </div>;
    }
}

FormRowCol.defaultProps = {
    className: ""
}

FormRowCol.propTypes = {
    col: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string
};