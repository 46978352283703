import React, {Component} from "react";
import {Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {getMsg, isValid} from "../Validation";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";
import {getValueFromFormOrDefault} from "./FormUtils";
import InputMask from 'react-input-mask';


class FormInput extends Component {
    static defaultProps = {
        colLg: 6,
        maxLength: false
    };

    render() {
        let props = this.props;
        let invalid = isValid(props.field);
        return <>
            <div hidden={props.hidden} className={"col-md-12 mt-2 col-lg-" + props.colLg}>
                <FormGroup row>
                    {this.props.withoutLabel ? '' : <Label for={props.formId + '.' + props.field} sm={props.labelSm}
                                                           className="text-md-right text-label">{props.label}{props.required &&
                    <span className="fred pl-1">*</span>}</Label>}
                    <Col sm={this.props.withoutLabel ? 12 : 9} className="my-auto">
                        <Input type={props.type} name={props.field} id={props.formId + '.' + props.field}
                               placeholder={props.placeholder}
                               invalid={invalid}
                               onBlur={this.props.onBlur}
                               onKeyUp={this.props.onKeyUp}
                               mask={this.props.mask}
                               tag={this.props.mask ? InputMask : undefined}
                               maxLength={this.props.maxLength ? this.props.maxLength: null}
                               onChange={(e) => {
                                   e.persist();
                                   props.setValueOnForm(props.formId, props.field, e.target.value)
                                   this.props.onChange && props.onChange(e);
                                   this.props.afterOnChange && props.afterOnChange(e.target.value);
                               }}
                               value={props.value ? props.value : getValueFromFormOrDefault(props.formId, props.field, '')}
                               disabled={props.disabled}
                               style={this.props.style}
                        />
                        {invalid && <FormFeedback style={{
                            display: "block",
                            marginLeft: '10px'
                        }}>{getMsg(props.field)}</FormFeedback>}
                    </Col>
                </FormGroup>
            </div>
        </>;
    }
}

FormInput.defaultProps = {
    colLg: 6,
    disabled: false,
    onBlur: undefined,
    onKeyUp: undefined,
    required: false,
    hidden: false,
    type: 'TEXT',
    withoutLabel: false,
    labelSm: 3
};

FormInput.propTypes = {
    colLg: PropTypes.number,
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    mask: PropTypes.string,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    withoutLabel: PropTypes.bool,
    hidden: PropTypes.bool,
    maxLength: PropTypes.number,
    labelSm: PropTypes.number
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormInput)