import React from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../../../redux/mappers";
import {withRouter} from "react-router-dom";
import {FormRow} from "../../../../common/Form/FormRow";
import FormRowField from "../../../../common/Form/FormRowField";
import {FormNothing} from "../../../../common/Form/FormNothing";
import FormRowText from "../../../../common/Form/FormRowText";
import {useTranslation} from "react-i18next";
import {
    decodeBiofilmClassification,
    decodeColorClassification,
    decodePedisClassification,
    decodeSmellClassification,
    decodeTexasDepthClassification,
    decodeTexasInfectionClassification,
    decodeVisualClassification
} from "../../../DictsDecode";

const NewVisitWoundClassificationDetails = (props) => {

    const {t} = useTranslation()

    return <>
        <FormRow>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("classification.visual") + ":"}>{decodeVisualClassification(props.classif.classifVisual)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("classification.odor") + ":"}>{decodeSmellClassification(props.classif.classifSmell)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("classification.texasDepth") + ":"}>{decodeTexasDepthClassification(props.classif.classifTexasDepth)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("classification.texasInfection") + ":"}>{decodeTexasInfectionClassification(props.classif.classifTexasInfection)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("classification.pedis") + ":"}>{decodePedisClassification(props.classif.classifPedis)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("classification.color") + ":"}>{decodeColorClassification(props.classif.classifColor)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("woundMicrobiology") + ":"}>{decodeBiofilmClassification(props.classif.microbiology)}</FormRowField>
            <FormRowField colLabel={5} colValue={7}
                          formGroupClass="mb-0"
                          label={t("woundDepth") + ":"}>{props.classif.depth}</FormRowField>
        </FormRow>
        {props.classif.commentBefore ?
            <FormRowText>
                <div className="card-header w-100 my-2"
                     style={{borderRadius: "calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px)"}}>
                                                                    <span
                                                                        className="text-label">{t('notesType.beforePlanimetry')}: </span><span
                    className="fw500"><pre>{props.classif.commentBefore}</pre></span>
                </div>
            </FormRowText>
            : <FormNothing/>}
        {props.classif.commentAfter ?
            <FormRowText>
                <div className="card-header w-100 my-2"
                     style={{borderRadius: "calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px) calc(0.6rem - 1px)"}}>
                                                                    <span
                                                                        className="text-label">{t('notesType.afterPlanimetry')}: </span><span
                    className="fw500"><pre>{props.classif.commentAfter}</pre></span>
                </div>
            </FormRowText>
            : <FormNothing/>}
    </>
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(NewVisitWoundClassificationDetails))
