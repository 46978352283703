import AppModal from "./AppModal";
import React from "react";
import {useTranslation} from "react-i18next";
import ButtonIcon from "../app-components/common/theme/ButtonIcon";

export const AppMobileVersion = (props) => {

    const {t} = useTranslation()


    return <AppModal isOpen={props.isOpen}
                     title={t("platformChooseMobileModal.choosePlatform")}
                     toggle={props.toggle}
    >
        <div className="row d-flex p-2 mb-2 justify-content-around">

            <a href="https://play.google.com/store/apps/details?id=esoz.personnel" target="_blank"
               rel="noreferrer noopener">
                <ButtonIcon icon="fab fa-android fa-10x mx-2 fwhite pointer"
                            popover={t("platformChooseMobileModal.goToGooglePlay")}
                            field={"android-store"}
                            buttonType={"btn-gray"}/>
            </a>
            <a href="https://apps.apple.com/pl/app/esoz/id1613496396?l=pl" target="_blank"
               rel="noreferrer noopener">
                <ButtonIcon icon="fab fa-apple fa-10x mx-3 fwhite pointer"
                            popover={t("platformChooseMobileModal.goToAppStore")}
                            field={"apple-store"}
                            buttonType={"btn-gray"}/>
            </a>
        </div>
    </AppModal>
}
