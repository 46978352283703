import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import FormWrapper from "../../../app-components/common/Form/FormWrapper";
import {PersonnelApi} from "../../../api/PersonnelApi";
import {FormSection} from "../../../app-components/common/Form/FormSection";
import FormHeader from "../../../app-components/common/Form/FormHeader";
import Mannequin from "../../../app-components/common/Mannequin";
import Utils from "../../../Utils";
import DataTable, {dataTableDefaultProps} from "../../../app-components/common/DataTable/DataTable";
import Dicts from "../../../app-components/patient/Dicts";
import {Tab, Tabs} from "react-bootstrap";
import Comparator from "../../../app-components/patient/visit/Comparator";
import WoundsClassification from "../../../app-components/patient/visit/WoundsClassification";
import {Translation} from 'react-i18next';
import {decodeWoundEtiology} from "../../../app-components/patient/DictsDecode";


class ViewPersonnelWounds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wound: undefined,
            classificationDetailsActive: false
        }
    }

    onClickClassificationDetails(key) {
        if (key === "classificationDetails") {
            this.setState({
                    classificationDetailsActive: true
                }
            )
        }
    }

    render() {
        return <Translation>
            {(t, {i18n}) => <>
                <FormWrapper>
                    <FormHeader title={t("wounds")}/>
                    <FormSection>

                        <DataTable
                            id="patientWounds"
                            api={PersonnelApi.getPatientWoundsTable}
                            cols={[
                                {
                                    header: "",
                                    sort: false,
                                    formatter: (v, r) => {
                                        return <i
                                            className={"text-secondary fas " + (r.id === this.state.wound?.id ? "fa-chevron-down" : "fa-chevron-right")}
                                            style={{width: '20px'}}/>
                                    }
                                },
                                {
                                    header: t("placement"),
                                    sort: false,
                                    formatter: (v, r) => {
                                        return <Mannequin
                                            size={0.5}
                                            thumbnail
                                            placement={r.placement}
                                        />
                                    }
                                },
                                {header: t("placementDescription"), field: "placementDescription"},
                                {
                                    header: t("etiology"),
                                    sort: false,
                                    formatter: (v, r) => {
                                        return decodeWoundEtiology(r.etiology)
                                    }
                                },
                                {
                                    header: t("creationDate"),
                                    sort: false,
                                    formatter: (v, r) => {
                                        return Utils.getFormattedDate(r.dateFrom, 'YYYY-MM-DD')
                                    }
                                },
                                {
                                    header: t("endDate"),
                                    sort: false,
                                    formatter: (v, r) => {
                                        return Utils.getFormattedDate(r.dateTo, 'YYYY-MM-DD')
                                    }
                                }
                            ]}
                            defaultPageSize={5}
                            defaultFilters={{
                                patientId: {
                                    field: "patient_id",
                                    value: this.props.context.patient.patient.id,
                                    type: "NUMBER",
                                    operation: "EQUALS",
                                    count: false
                                }
                            }}
                            defaultSort={{field: 'date_from', direction: 'DESC'}}
                            trOnClick={(r) => () => {
                                if (this.state.wound?.id === r.id) this.setState({wound: undefined})
                                else this.setState({wound: r})

                            }}
                            trActive={(r) => this.state?.wound?.id === r.id}
                            trActiveBody={(r) => {
                                return <Tabs defaultActiveKey="planimetries" id="wouds-tabs"
                                             className="mb-3 tab-header h5 fwhite fw600"
                                             onSelect={(key) => this.onClickClassificationDetails(key)}>
                                    <Tab eventKey="planimetries" title={t("planimetries")}>
                                        <Comparator wound={{id: r.id}}/>
                                    </Tab>
                                    <Tab eventKey="classificationDetails" title={t("classificationAndComments")}>
                                        {this.state.classificationDetailsActive &&
                                            <WoundsClassification wound={{id: r.id}}/>}
                                    </Tab>
                                </Tabs>
                            }}
                        />
                    </FormSection>
                </FormWrapper></>
            }
        </Translation>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewPersonnelWounds)
