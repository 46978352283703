import {store} from 'app/redux/redux'
import moment from "moment";
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import React from "react";
import {toByteArray} from "base64-js";
import {saveAs} from "file-saver"
import {PersonnelApi} from "./api/PersonnelApi";
import {decodeMannequin, getMomentCode} from "./app-components/patient/DictsDecode";
import i18n from "i18next";


const Utils = {

    updateDeepObject: function (form, fields, value) {
        const key = fields.shift();
        if (fields.length > 0) {
            this.updateDeepObject(form[key], fields, value)
        } else {
            form[key] = value
        }
    },
    hasPrivilege: function (action) {
        return store.getState().user.privileges.includes(action);
    },
    isMd: function () {
        return store.getState().user.authorities.includes('md');
    },
    isNurse: function () {
        return store.getState().user.authorities.includes('nurse');
    },
    isAdmin: function () {
        return store.getState().user.authorities.includes('admin');
    },
    addPrecision(value, places) {
        return value.toFixed(places ? places : 2);
    },
    getFormattedDate(date, format) {
        if (date) {
            if (format) {
                return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format(format)
            } else {
                return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
            }
        }
        return '';
    },

    dateToStr(date) {

        return moment(date).format("YYYY-MM-DD")
    },

    timeToStr(date) {
        if (date) {
            return moment(date).format("HH:mm:ss")
        }
        return '';
    },

    utcToDate(date) {
        return moment.utc(date).local().toDate()
    },

    utcToTimeStr(date) {
        return moment.utc(date).local().format('HH:mm')
    },

    getCollapsibleIcon(state) {
        return <i className={state ? 'fas fa-chevron-down pr-1' : 'fas fa-chevron-right pr-1'}/>
    },

    parsePlacement(placement) {
        let umiejscowienia = []

        Object.keys(placement).forEach(function (key) {
            let value = placement[key]
            if (value === true) {
                umiejscowienia.push(decodeMannequin(key))
            }
        });

        return umiejscowienia.join(', ')
    },

    parseSex(pesel) {
        if ((pesel[9] % 2) === 0) {
            return 'kobieta'
        } else {
            return 'mezczyzna'
        }
    },

    parseDateOfBirth(pesel) {
        let msc = pesel.substr(2, 2)
        let mscPrint = ('' + ((msc - 1) % 20 + 1)).padStart(2, '0');
        let dzien = pesel.substr(4, 2)
        let rok = (parseInt(pesel.substr(0, 2)) + (19 + parseInt((parseInt(msc) - 1) / 20)) * 100).toString()
        let data = moment(rok + "-" + mscPrint + "-" + dzien).toDate()
        if (isNaN(data.getTime())) {
            return undefined
        }
        return data
    },

    todayMidnight() {
        const nowDt = new Date();
        nowDt.setHours(0, 0, 0, 0);
        return nowDt;
    },

    setTime(date, h, m, s) {
        const newDt = new Date(date);
        newDt.setHours(h, m, s);
        return newDt;
    },

    filterEmptyPropertiesToArgument: (objectToFilter, replaceArg) => {
        for (const property in objectToFilter) {
            const value = objectToFilter[property]
            objectToFilter[property] = value ? value : replaceArg;
        }
    },

    downloadFile: (base64, nameFile, typeFile) => {
        const byteArray = toByteArray(base64)
        const file = new Blob([byteArray], {type: typeFile});
        saveAs(file, nameFile)
    },

    downloadFileBytes: (bytes, nameFile, typeFile) => {
        const file = new Blob([bytes], {type: typeFile});
        saveAs(file, nameFile)
    },

    isMobile: (width) => {
        return width < 768
    },

    getApk: () => {
        PersonnelApi.getApk().then(json => {
            Utils.downloadFile(json.data.base64, "personnel-esoz.apk", "application/octet-stream")
        })
    },
    setCurrentDateRounded: () => {
        const currentDate = new Date();
        if (currentDate.getMinutes() <= 30 && currentDate.getMinutes() >= 15) {
            currentDate.setMinutes(30);
        } else if (currentDate.getMinutes() > 30) {
            currentDate.setHours(currentDate.getHours() + 1);
            currentDate.setMinutes(0);
        } else {
            currentDate.setMinutes(0);
        }
        return currentDate.toISOString();
    },
    emptyIfUndefined: (str) => {
        return str ? str : ""
    },
    isObject: (obj) => {
        return obj instanceof Object;
    },
    getDayOfWeek: (date) => {
        moment.locale(getMomentCode(i18n.resolvedLanguage))
        const day = moment(date || null).format('dddd')
        return day.charAt(0).toUpperCase() + day.slice(1)
    }

};

export default Utils;
