import React, {Component} from "react";
import {Form} from "reactstrap";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import {cloneWithFormId} from "./FormUtils";
import * as PropTypes from "prop-types";
import {Translation} from 'react-i18next';


class FormWrapper extends Component {

    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
    }


    save() {
        let validate = this.props.validate ? this.props.validate() : true;
        if (validate) {
            this.props.save()
            this.props.clearValidation()
        }
    }

    render() {
        return <Translation>
            {(t) => <div className="card mb-2">
                <Form onSubmit={e => {
                    e.preventDefault();
                }}>
                    {cloneWithFormId(this.props.children, this.props.formId)}
                    <div className="row bgr-white mx-1 d-flex align-items-end justify-content-end">
                        {this.props.save && <div className="btn btn-primary mt-4 mr-4 mb-4"
                                                 onClick={() => this.save()}>{t(this.props.saveText)}</div>}
                    </div>
                </Form>
            </div>}
        </Translation>;
    }
}

FormWrapper.defaultProps = {
    saveText: "commonForm.buttonSave"
}

FormWrapper.propTypes = {
    children: PropTypes.node,
    save: PropTypes.func,
    saveText: PropTypes.string,
    validate: PropTypes.func
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormWrapper)
