import {Api} from "./Api";

export class PersonnelApi extends Api {


    static apiUrl = window.var.API_HOST + '/api/v1/personnel';

    static addPatient(form) {
        return PersonnelApi.post("/patient/add", form)
    }

    static checkPlannedVisitExists(form) {
        return PersonnelApi.post("/visit/check/planned-visit/exists", form)
    }

    static checkPatientVisitsInClinic(patientId, clinicId)  {
        return PersonnelApi.get("/visitarch/patient/" + patientId + "/clinic/" + clinicId)
    }

    static editPatient(form) {
        return PersonnelApi.post("/patient/edit", form)
    }

    static resetPatientAccessCode(form) {
        return PersonnelApi.post("/patient/resetAccessCode", form)
    }

    static findPatient(criteria) {
        return PersonnelApi.post("/patient/find", criteria)
    }

    static getPatient(patientId) {
        return PersonnelApi.get("/patient/" + patientId)
    }

    static getPatientData(patientId, clinicId) {
        return PersonnelApi.get("/patient/" + patientId + "/clinic/" + clinicId + "/data")
    }

    static getPatientContact(id) {
        return PersonnelApi.get("/patient/contactData/" + id)
    }

    static getPatientAddresses(patientId) {
        return PersonnelApi.get("/patient/addresses/" + patientId)
    }

    static deletePatientAddress(patientId) {
        return PersonnelApi.delete("/patient/addresses/" + patientId)
    }

    static addPatientAddress(form) {
        return PersonnelApi.post("/patient/addresses", form)
    }

    static editPatientAddress(form) {
        return PersonnelApi.post("/patient/address", form)
    }

    static getPatientAuthorizedPersons(patientId) {
        return PersonnelApi.get("/patient/authorizedPersons/" + patientId)
    }

    static getPatientClinics(patientId) {
        return PersonnelApi.get("/patient/clinics/" + patientId)
    }

    static getPatientPreferences(patientId) {
        return PersonnelApi.get("/patient/preferences/" + patientId)
    }

    static addPatientToClinic(clinicId, patientId) {
        return PersonnelApi.put("/patient/clinics", {
            clinicId: clinicId,
            patientId: patientId
        })
    }

    static addExistingPatientToClinic(clinicId, patientId) {
        return PersonnelApi.put("/patient/external/clinics", {
            clinicId: clinicId,
            patientId: patientId
        })
    }

    static deletePatientAuthorizedPersons(authorizedPersonId) {
        return PersonnelApi.delete("/patient/authorizedPersons/" + authorizedPersonId)
    }

    static addPatientAuthorizedPerson(form) {
        return PersonnelApi.post("/patient/authorizedPersons", form)
    }

    static editAatientAuthorizedPerson(form) {
        return PersonnelApi.post("/patient/authorizedPerson", form)
    }

    static getPatientComorbidities(patientId) {
        return PersonnelApi.get("/patient/comorbidities/" + patientId)
    }

    static addPatientComorbiditie(form) {
        return PersonnelApi.post("/patient/comorbidities/add", form)
    }

    static deletePatientComorbiditie(comorbiditieId) {
        return PersonnelApi.delete("/patient/comorbidities/" + comorbiditieId)
    }

    static getDictIcd10(criteria) {
        return PersonnelApi.post("/dict/icd10/find", criteria)
    }

    static getDictMedicine(criteria) {
        return PersonnelApi.post("/dict/medicine/find", criteria)
    }

    static getDictMedicineExamination(criteria) {
        return PersonnelApi.post("/dict/medicalExaminaton/find", criteria)
    }

    static savePatientVisit(form, patientId) {
        return PersonnelApi.post("/visit/", {...form, patientId: patientId})
    }

    static savePatientVisitSnapshot(form, patientId, personnelId) {
        return PersonnelApi.post("/visit/snapshot", {...form, patientId: patientId, personnelId: personnelId}, true)
    }

    static getPatientVisitSnapshot(patientId, personnelId, clinicId) {
        return PersonnelApi.get("/visit/snapshot/patient/" + patientId + '/personnel/' + personnelId + '/clinic/' + clinicId)
    }

    static deletePatientVisitSnapshot(patientId, personnelId, clinicId) {
        return PersonnelApi.delete("/visit/snapshot/patient/" + patientId + '/personnel/' + personnelId + '/clinic/' + clinicId)
    }

    static getPatientWounds(patientId) {
        return PersonnelApi.get("/visit/wound/" + patientId)
    }

    static getPatientWoundsTable(criteria) {
        return PersonnelApi.post("/visit/wounds", criteria)
    }

    static addPatientWound(form, patientId) {
        return PersonnelApi.post("/visit/wound", {
            ...form,
            id: 0,
            patientId: patientId
        })
    }

    static editPatientWound(form) {
        return PersonnelApi.patch("/visit/wound", form)
    }

    static finishTreatmentOfWound(form) {
        return PersonnelApi.patch("/visit/wound/finish", form)
    }

    static getVisitSchedulerOfPatient(criteria) {
        return PersonnelApi.post("/scheduled/visit/find/patient", criteria)
    }

    static getPatientVisitCalendar(kalendarzId) {
        return PersonnelApi.get("/visit/calendar/" + kalendarzId)
    }

    static getHistoryVisitsOfPatient(criteria, currentClinicId) {
        return PersonnelApi.post(`/visit/find/history/patient/${currentClinicId}`, criteria)
    }

    static isAllVisitExternalForCurrentClinic(patienId, currentClinicId) {
        return PersonnelApi.post(`/visit/find/history/patient/${currentClinicId}/${patienId}`)
    }

    static getVisitSchedulerOfPersonnel(criteria) {
        return PersonnelApi.post("/scheduled/visit/get/personnel", criteria)
    }

    static getPlannedAllVisits(criteria) {
        return PersonnelApi.post("/scheduled/visit/get/all", criteria)
    }

    static addScheduledVisit(form) {
        return PersonnelApi.post("/scheduled/visit/add", form)
    }

    static deleteScheduledVisit(visitId) {
        return PersonnelApi.delete("/scheduled/visit/delete/" + visitId)
    }

    static getImagesForPersonnel(form) {
        return PersonnelApi.post("/gallery/get/photos/", form)
    }

    static getAllImagesForPersonnel(form) {
        return PersonnelApi.post("/gallery/get/all/photos/", form)
    }

    static getPatientImagesForVisit(form) {
        return PersonnelApi.post("/gallery/get/patientPhotos/", form)
    }

    static getPatientDocumentations(patientId) {
        return PersonnelApi.get("/gallery/get/patient-documentations/" + patientId)
    }

    static getPatientDocumentationsForVisit(patientId, calendarId) {
        return PersonnelApi.get("/gallery/get/patient-documentations/" + patientId + "/calendar/" + calendarId)
    }

    static getImages(url) {
        return PersonnelApi.getImagesSecured(url)
    }

    static getDicomsOfPatient(patientId, woundId) {
        return PersonnelApi.get("/dicom/patient/" + patientId + "/wound/" + woundId)
    }

    static getMedicalDocumentation(patientId) {
        return PersonnelApi.get("/medical-documentation/patient/" + patientId)
    }

    static getMedicalDocumentationNotConnectedWithMedicalExamination(form) {
        return PersonnelApi.post("/medical-documentation/medical-examination/notconnected", form)
    }

    static getMedicalDocumentationAddedToMedicalExamination(form) {
        return PersonnelApi.post("/medical-documentation/medical-examination/added", form)
    }


    static addCommentToMedicalDocumentation(form) {
        return PersonnelApi.post("/medical-documentation/add/comment", form)
    }

    static deleteCommentFromMedicalDocumentation(commentId) {
        return PersonnelApi.delete("/medical-documentation/comment/" + commentId)
    }

    static editDocumentationComment(form) {
        return PersonnelApi.patch("/medical-documentation/comment", form)
    }

    static addTitleToMedicalDocumentation(form) {
        return PersonnelApi.post("/medical-documentation/add/title", form)
    }

    static showAllCommentsToMedicalDocumentation(documentationId) {
        return PersonnelApi.get("/medical-documentation/" + documentationId)
    }


    static addImageToMedicineExamination(form) {
        return PersonnelApi.post("/diagnostics/medicalExamination/add/images", form)
    }

    static deleteImageFromMedicineExamination(id) {
        return PersonnelApi.delete("/diagnostics/medicalExamination/" + id)
    }

    static addMedicalDocumentation(payload) {
        return PersonnelApi.sendFilesWithData("/medical-documentation/add", payload)
    }

    static addMedicalDocumentationFromPatientFile(payload) {
        return PersonnelApi.post("/medical-documentation/addPatient", payload)
    }

    static deleteMedicalDocumentation(documentationId) {
        return PersonnelApi.delete("/medical-documentation/delete/" + documentationId)
    }

    static addMedicalExamination(form) {
        return PersonnelApi.post("/diagnostics/medicalExamination/add", form)
    }

    static getMedicalExaminationIncompleteForPatient(patientId) {
        return PersonnelApi.get("/diagnostics/medicalExamination/incomplete/patient/" + patientId)
    }

    static getMedicalExaminationCompletedForPatient(patientId) {
        return PersonnelApi.get("/diagnostics/medicalExamination/complete/patient/" + patientId)
    }

    static getMedicalExaminationsForPatient(patientId) {
        return PersonnelApi.get("/diagnostics/medicalExamination/patient/" + patientId)
    }

    static getMedicalExaminationForPatient(patientId, examinationId) {
        return PersonnelApi.get("/diagnostics/medicalExamination/" + examinationId + "/patient/" + patientId)
    }

    static deleteMedicalExamination(medicalExaminationId) {
        return PersonnelApi.delete("/diagnostics/medicalExamination/patient/" + medicalExaminationId)
    }

    static addCommentToMedicalExamination(form) {
        return PersonnelApi.post("/diagnostics/medicalExamination/add/comment", form)
    }

    static addFirstCommentToMedicalExamination(form) {
        return PersonnelApi.post("/diagnostics/medicalExamination/add/first-comment", form)
    }

    static addPatientDisease(form, patientId) {
        return PersonnelApi.put("/visit/disease", {
            ...form,
            id: 0,
            patientId: patientId
        })
    }

    static editPatientDisease(form) {
        return PersonnelApi.patch("/visit/disease", form)
    }

    static getPatientDiseases(criteria) {
        return PersonnelApi.post("/visit/disease/find", criteria)
    }

    static getPersonnel(personnelUuid) {
        return PersonnelApi.get("/" + personnelUuid)
    }

    static getArchClassifications(diseaseId, woundId) {
        return PersonnelApi.get("/visitarch/disease/" + diseaseId + "/wound/" + woundId + "/classifications")
    }

    static getArchDocRecForPersonnel(diseaseId) {
        return PersonnelApi.get("/visitarch/disease/" + diseaseId + "/recDocForPersonnel")
    }

    static getArchDocRecForPatient(diseaseId) {
        return PersonnelApi.get("/visitarch/disease/" + diseaseId + "/recDocForPatient")
    }

    static getArchNurseRec(diseaseId) {
        return PersonnelApi.get("/visitarch/disease/" + diseaseId + "/recNurse")
    }

    static updatePersonnelAccount(form) {
        return PersonnelApi.post("/update", form)
    }

    static updatePersonnelPassword(form) {
        return PersonnelApi.post("/update/password", form)
    }

    static getAllPersonnelForClinic(id) {
        return PersonnelApi.get("/clinic/" + id + "/personnel")
    }

    static getAllMdForClinic(id) {
        return PersonnelApi.get("/clinic/" + id + "/md")
    }

    static getAllClinicForPersonnel(personnelId) {
        return PersonnelApi.get("/clinic/" + personnelId)
    }

    static getQuestForScheduleVisit(form) {
        return PersonnelApi.post("/quest/schedule-visit", form)
    }

    static getCovidPatientQuest(form) {
        return PersonnelApi.post("/quest/patient/schedule-visit/covid", form)
    }

    static getCovidPersonnelQuest(form) {
        return PersonnelApi.post("/quest/personnel/schedule-visit/covid", form)
    }

    static getCovidQuestColor(form) {
        return PersonnelApi.post("/quest/covid/color", form)
    }

    static saveAllQuests(form) {
        return PersonnelApi.post("/quest/all/schedule-visit/add", form)
    }

    static savePersonnelQuest(form) {
        return PersonnelApi.post("/quest/schedule-visit/add", form)
    }

    static savePatientQuest(form) {
        return PersonnelApi.post("/quest/patient/schedule-visit/add", form)
    }

    static checkAllQuestRequiredForScheduleVisit(form) {
        return PersonnelApi.post("/quest/required/schedule-visit", form)
    }

    static getApk() {
        return PersonnelApi.get("/download/apk-personnel")
    }

    static getMdRecForPatient(patientId, scheduleVisitId) {
        return PersonnelApi.get("/visitarch/patient/" + patientId + "/" + scheduleVisitId + "/md/patient/rec")
    }

    static getMdRecForPersonnel(patientId, scheduleVisitId) {
        return PersonnelApi.get("/visitarch/patient/" + patientId + "/" + scheduleVisitId + "/md/personnel/rec")
    }

    static getNurseRecForVisit(patientId, scheduleVisitId) {
        return PersonnelApi.get("/visitarch/patient/" + patientId + "/" + scheduleVisitId + "/nurse/rec")
    }

    static getAllVisibleClinicLists() {
        return PersonnelApi.get("/clinic/visible/list/")
    }

    static getAllClinics() {
        return PersonnelApi.get("/clinic/all/")
    }

    static getClinicsAssignedToPersonel() {
        return PersonnelApi.get("/clinic/assigned/")
    }

    static getClinicPersonnel(clinicId) {
        return PersonnelApi.get("/clinic/" + clinicId + "/personnel")
    }

    static getOutsideClinicPersonnel(clinicId) {
        return PersonnelApi.get("/clinic/outside/" + clinicId + "/personnel")
    }

    static getWoundsClassiff(woundId) {
        return PersonnelApi.get("/visit/wounds/" + woundId + "/classif")
    }

    static existPesel(pesel) {
        return PersonnelApi.get("/patient/pesel-exist/" + pesel)
    }

    static findPatientByPesel(pesel) {
        return PersonnelApi.get("/patient/find/by/" + pesel)
    }

    static getVersionApp() {
        return PersonnelApi.get("/version-app")
    }

    static getMedicalRegisterPackaging(id) {
        return PersonnelApi.get("/medical-register/packaging/" + id)
    }

    static checkPatientHasPortal(id) {
        return PersonnelApi.get("/patient/" + id + "/check/portal")
    }

    static checkPatientConfirmEmail(uuid) {
        return PersonnelApi.get("/patient/" + uuid + "/keycloak/check/email-confirmed")
    }

    static confirmPatientEmail(id) {
        return PersonnelApi.get("/patient/" + id + "/keycloak/email-confirm")
    }

    static deletePlanimetryPhoto(id) {
        return PersonnelApi.get("/gallery/delete/image/" + id)
    }

    static updatePersonnelPreferences(form) {
        return PersonnelApi.post("/update/preferences", form)
    }

    static getPersonnelPreferences() {
        return PersonnelApi.get("/get/preferences")
    }

    static saveVisitTags(scheduleVisitId, tags) {
        return PersonnelApi.post("/visit/params/" + scheduleVisitId, tags)
    }

    static getParamsTag(scheduleVisitId) {
        return PersonnelApi.get("/visit/params/" + scheduleVisitId)
    }

    static getJaasJwt(roomName) {
        return PersonnelApi.get("/jass/" + roomName)
    }

    static getSurveyAfterVisit(clinicId) {
        return PersonnelApi.get("/surveys/" + clinicId + "/AFTER_VISIT_PERSONNEL")

    }

    static saveSurveyAfterVisit(form) {
        return PersonnelApi.post("/surveys", form)
    }

}

