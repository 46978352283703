import {
    ajaxStart,
    ajaxStop,
    clearContext,
    clearPreferences,
    clearSelectedValidation, clearTenant,
    clearUser,
    clearValidation,
    closeSidebar,
    dismissToast,
    openSidebar,
    pushToast,
    setColorStatusForm,
    setContext,
    setContextPatient,
    setDataTable,
    setDataTableParams,
    setForm,
    setIsSensitiveDataVisible,
    setKeycloak,
    setLang,
    setPersonnelList,
    setPreferences, setTenant,
    setUser,
    setValidation,
    setValueOnForm,
    setWindowSize,
    toggleSidebar,
    unsetDataTable,
    unsetForm,
    updateDataTable
} from "./redux";
import {setCovidPatientFormActive, setCovidPersonnelFormActive} from "./questForm";

export const mapStorageToProps = state => ({
    user: state.user,
    tenant: state.tenant,
    lang: state.lang,
    toast: state.toast,
    loading: state.loading,
    validation: state.validation,
    dataTable: state.dataTable,
    form: state.form,
    windowSize: state.windowSize,
    sidebar: state.sidebar,
    context: state.context,
    keycloak: state.keycloak,
    isSensitiveDataVisible: state.isSensitiveDataVisible,
    colorStatusForm: state.colorStatusForm,
    personnelList: state.personnelList,
    questForm: state.questForm,
    preferences: state.preferences,
});

export const mapStorageDispatchToProps = {
    setLang,
    setUser,
    setTenant,
    clearUser,
    pushToast,
    dismissToast,
    ajaxStart,
    ajaxStop,
    setValidation,
    clearValidation,
    clearSelectedValidation,
    setDataTable,
    updateDataTable,
    setDataTableParams,
    unsetDataTable,
    setValueOnForm,
    setForm,
    unsetForm,
    setWindowSize,
    toggleSidebar,
    openSidebar,
    closeSidebar,
    clearContext,
    clearTenant,
    setContext,
    setContextPatient,
    setKeycloak,
    setIsSensitiveDataVisible,
    setColorStatusForm,
    setPersonnelList,
    setCovidPatientFormActive,
    setCovidPersonnelFormActive,
    setPreferences,
    clearPreferences
};
