import React from "react";
import FormWrapper from "../app-components/common/Form/FormWrapper";
import {FormSection} from "../app-components/common/Form/FormSection";
import {FormRow} from "../app-components/common/Form/FormRow";
import FormInput from "../app-components/common/Form/FormInput";
import {getValueFromFormOrDefault, validateRequiredFields} from "../app-components/common/Form/FormUtils";
import AppModal from "./AppModal";
import html2canvas from "html2canvas";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../redux/mappers";
import {Button, Col, FormGroup, Label} from "reactstrap";
import {browserName, fullBrowserVersion} from 'react-device-detect';
import {IssueApi} from "../api/IssueApi";
import i18n from "i18next";
import {Translation} from 'react-i18next';

const FORM_NEW_ISSUE = "FORM_NEW_ISSUE"

class AppReportIssue extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            reportIssueModalOpen: false,
            patientDataWasVisible: false,
            connInfoReady: false
        };
    }

    addIssue = () => {
        if (validateRequiredFields(FORM_NEW_ISSUE, ['description', 'steps', 'outcome'])()) {
            IssueApi.addIssue(this.props.form[FORM_NEW_ISSUE]).then((json) => {
                if (json.meta.status === "SUCCESS") {
                    this.props.setForm(FORM_NEW_ISSUE, {})
                    this.props.clearValidation();
                    this.toggleCreateIssue()
                    this.props.pushToast('s', i18n.t('toast.errorReported'))
                } else {
                    this.props.setForm(FORM_NEW_ISSUE, {})
                    this.props.clearValidation();
                    this.toggleCreateIssue()
                }
            })
            this.setState({
                connInfoReady: false
            })
        }
    }

    checkConnectionToRedmine = () => {

        const p = this.props
        IssueApi.checkConnection().then((json) => {
            p.setValueOnForm(FORM_NEW_ISSUE, "connOk", json.data)
            this.setState({
                connInfoReady: true
            })
        })
    }

    toggleCreateIssue = () => {
        const p = this.props
        if (!this.state.reportIssueModalOpen) {
            this.checkConnectionToRedmine()
            p.setValueOnForm(FORM_NEW_ISSUE, "currentTime", new Date().toLocaleString())
            p.setValueOnForm(FORM_NEW_ISSUE, "loggedInUser", p.user.userInfo.preferred_username)
            p.setValueOnForm(FORM_NEW_ISSUE, "currentPath", window.location.href)
            p.setValueOnForm(FORM_NEW_ISSUE, "currentReduxState", JSON.stringify(p, null, 2))
            p.setValueOnForm(FORM_NEW_ISSUE, "loggedInUserRoles", p.user.authorities)
            p.setValueOnForm(FORM_NEW_ISSUE, "clinicid", p.user.clinic.name + " [id=" + p.user.clinic.id + "]")
            p.setValueOnForm(FORM_NEW_ISSUE, "patient", p.context.patient ? p.context.patient.personalData.firstname + " " + p.context.patient.personalData.lastname + " [id=" + p.context.patient.personalData.patientId + "] [PESEL=********" + p.context.patient.personalData.pesel.substring(8) + "]" : "BRAK")
            p.setValueOnForm(FORM_NEW_ISSUE, "browserUsed", browserName + " v" + fullBrowserVersion)
            this.setState({patientDataWasVisible: this.props.isSensitiveDataVisible})
            html2canvas(document.body).then(function (canvas) {
                p.setValueOnForm(FORM_NEW_ISSUE, "screenshot", canvas.toDataURL())
            })
        } else {
            this.setState({
                connInfoReady: false
            })
            p.unsetForm(FORM_NEW_ISSUE)
        }

        this.setState({reportIssueModalOpen: !this.state.reportIssueModalOpen})
    }

    render() {
        return <Translation>
            {
                (t, {i18n}) => <>
                    <div style={{position: "fixed", bottom: 10, left: 10, zIndex: 99999999, cursor: "pointer"}}
                         onClick={this.toggleCreateIssue}><Button color="danger"><i className="fas fa-bug"/></Button>
                    </div>
                    <AppModal className="modal-lg"
                              isOpen={this.state.reportIssueModalOpen}
                              saveText={t("commonForm.buttonSave")}
                              title={t("bugReport.reportProblem")}
                              toggle={this.toggleCreateIssue}
                              saveAction={this.addIssue}
                              saveDisabled={this.state.connInfoReady && !this.props.form[FORM_NEW_ISSUE].connOk}
                              dontCloseOnSave>
                        <FormWrapper formId={FORM_NEW_ISSUE}>
                            <FormSection>
                                <FormRow>
                                    <div className="col-md-12 mt-2 col-lg-12">
                                        <FormGroup row>
                                            <Label sm={3}
                                                   className="text-md-right text-label">{t("bugReport.readyForBug")}</Label>
                                            <Col sm={9} className="my-auto">
                                                {this.state.connInfoReady ? <span
                                                        className={"dot " + (this.props.form[FORM_NEW_ISSUE] && this.props.form[FORM_NEW_ISSUE].connOk ? "bg-success" : "bg-danger")}/> :
                                                    <span>{t("bugReport.serverConnecting")}</span>}
                                                <br/>
                                                <span>{(this.state.connInfoReady && !this.props.form[FORM_NEW_ISSUE].connOk) ?
                                                    <span>{t("bugReport.connectingProblem")} <Button
                                                        size="sm" color="info"
                                                        onClick={this.checkConnectionToRedmine}><i
                                                        className="fas fa-sync"/></Button></span> : ""}</span>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <FormInput colLg={12}
                                               type="text"
                                               required
                                               label={t("bugReport.uuidLastError")}
                                               field='uuid'
                                               formId={FORM_NEW_ISSUE}
                                               disabled/>
                                    <FormInput colLg={12}
                                               type="textarea"
                                               required label={t("bugReport.problemDescription")}
                                               placeholder={t("bugReport.detailsProblemDescription")}
                                               field='description'
                                               style={{minHeight: 200}}
                                               disabled={this.state.connInfoReady && !this.props.form[FORM_NEW_ISSUE].connOk}
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="textarea"
                                               required label={t("bugReport.stepsToRecreate")}
                                               style={{minHeight: 200}}
                                               disabled={this.state.connInfoReady && !this.props.form[FORM_NEW_ISSUE].connOk}
                                               field='steps'
                                               placeholder={t("bugReport.stepsToRecreateDescr")}
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="textarea"
                                               required label={t("bugReport.errorConsequences")}
                                               placeholder={t("bugReport.errorConsequencesDescr")}
                                               style={{minHeight: 200}}
                                               disabled={this.state.connInfoReady && !this.props.form[FORM_NEW_ISSUE].connOk}
                                               field='outcome'
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="text"
                                               label={t("bugReport.dateTime")}
                                               disabled
                                               field='currentTime'
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="text"
                                               label={t("bugReport.loggedUser")}
                                               disabled
                                               field='loggedInUser'
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="text"
                                               label={t("bugReport.userRole")}
                                               disabled
                                               field='loggedInUserRoles'
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="text"
                                               label={t("bugReport.selectedFacility")}
                                               disabled
                                               field='clinicid'
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="text"
                                               label={t("bugReport.displayedPage")}
                                               disabled
                                               field='currentPath'
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="textarea"
                                               required label={t("bugReport.applicationState")}
                                               style={{minHeight: 200}}
                                               field='currentReduxState'
                                               disabled
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="textarea"
                                               required label={t("bugReport.screenshot")}
                                               hidden
                                               style={{minHeight: 200}}
                                               field='screenshot'
                                               disabled
                                               formId={FORM_NEW_ISSUE}/>
                                    <Label className="p-2">{t("bugReport.attachedScreenshot")}</Label>
                                    <img style={{maxWidth: "100%", border: "2px solid black"}}
                                         src={getValueFromFormOrDefault(FORM_NEW_ISSUE, 'screenshot', "")}
                                         alt="screenshot"/>
                                    <hr/>
                                    <FormInput colLg={12}
                                               type="text"
                                               required label={t("bugReport.diagnosedPatient")}
                                               field='patient'
                                               disabled
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="text"
                                               required label={t("bugReport.usedBrowser")}
                                               field='browserUsed'
                                               disabled
                                               formId={FORM_NEW_ISSUE}/>
                                    <FormInput colLg={12}
                                               type="textarea"
                                               style={{minHeight: 200}}
                                               label={t("bugReport.additionaInsights")}
                                               placeholder={t("bugReport.additionaInsightsDescr")}
                                               field='additionalNotes'
                                               disabled={this.state.connInfoReady && !this.props.form[FORM_NEW_ISSUE].connOk}
                                               formId={FORM_NEW_ISSUE}/>
                                </FormRow>
                            </FormSection>
                        </FormWrapper>
                    </AppModal></>}</Translation>
    }
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppReportIssue));
