import React from "react";
import {Col, FormFeedback, FormGroup, Label} from "reactstrap";
import {getMsg, isValid} from "../Validation";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";
import {getDateValueFromFormOrDefault} from "./FormUtils";
import DatePicker from "react-widgets/DatePicker";
import moment from "moment";


const FormDatePicker = (props) => {

    let invalid = isValid(props.field);
    return <div className={"col-md-12 mt-2 col-lg-" + props.colLg}>
        <FormGroup row>
            <Label for={props.formId + '.' + props.field} sm={props.sizeLabel}
                   className="text-md-right text-label">{props.label}{props.required &&
                <span className="fred pl-1">*</span>}</Label>
            <Col sm={props.sizeCol} className="my-auto">
                <DatePicker
                    parse={str => {
                        let v = getDateValueFromFormOrDefault(props.formId, props.field, null)
                        let momentDate = moment(str, props.customFormat ? props.customFormat : 'DD-MM-YYYY, HH:mm');
                        let nd = momentDate.toDate()
                        if (v === null) {
                            props.setValueOnForm(props.formId, props.field, nd);
                            props.afterOnChange && props.afterOnChange(nd);
                        }
                    }}
                    valueFormat={props.customFormat}
                    onChange={(e, dateString) => {
                        if (e !== null) {
                            e.setMilliseconds(0)
                            e.setSeconds(0)
                            if (props.time) {
                                props.setValueOnForm(props.formId, props.field, e);
                                props.afterOnChange && props.afterOnChange(e);
                            } else {
                                props.setValueOnForm(props.formId, props.field, e ? new Date(e.getFullYear(), e.getMonth(), e.getDate()) : e);
                                props.afterOnChange && props.afterOnChange(e);
                            }
                        }

                        if (e === null && dateString !== null) {
                            let momentDate = moment(dateString, 'DD-MM-YYYY, HH:mm');
                            let date = momentDate.toDate();
                            props.setValueOnForm(props.formId, props.field, date);
                            props.afterOnChange && props.afterOnChange(props.formId, props.field, date);
                        }

                    }}
                    value={getDateValueFromFormOrDefault(props.formId, props.field, null)}
                    min={props.min}
                    max={props.max}
                    includeTime={props.time}
                    date={props.date}
                    disabled={props.disabled}
                />
                {invalid && <FormFeedback style={{
                    display: "block",
                    marginLeft: '10px'
                }}>{getMsg(props.field)}</FormFeedback>}
            </Col>
        </FormGroup>
    </div>;

}

FormDatePicker.defaultProps = {
    colLg: 6,
    disabled: false,
    required: false,
    time: false,
    date: true,
    sizeLabel: 3,
    sizeCol: 9
};

FormDatePicker.propTypes = {
    colLg: PropTypes.number,
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    required: PropTypes.bool,
    time: PropTypes.bool,
    date: PropTypes.bool,
    sizeLabel: PropTypes.number,
    sizeCol: PropTypes.number

};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormDatePicker)
