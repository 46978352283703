import _ from 'lodash'
import Dicts from "./Dicts";
import i18n from "i18next";

export const decodeAddressType = (key) => {
    const value = _.first(_.filter(Dicts.addressType(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''

}

export const decodePosition = (key) => {

    let value = _.first(_.filter(Dicts.personnelRole(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeGender = (key) => {
    let value = _.first(_.filter(Dicts.gender(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeVisitConfirmation = (key) => {
    let value = _.first(_.filter(Dicts.visitConfirmation(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeVisualClassification = (key) => {
    let value = _.first(_.filter(Dicts.visualClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeSmellClassification = (key) => {
    let value = _.first(_.filter(Dicts.smellClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodePedisClassification = (key) => {
    let value = _.first(_.filter(Dicts.pedisClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeTexasDepthClassification = (key) => {
    let value = _.first(_.filter(Dicts.texasDepthClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeNotesType = (key) => {
    let value = _.first(_.filter(Dicts.notesType(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeDocumentType = (key) => {
    let value = _.first(_.filter(Dicts.documentType(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeTexasInfectionClassification = (key) => {
    let value = _.first(_.filter(Dicts.texasInfectionClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeVisitTypeByProfession = (profession) => {
    if (profession === 'md') return _.filter(Dicts.visitType(), (t) => {
        return t.value.includes('lekarska') || t.value.includes('kontrolna')
    })
    else if (profession === 'nurse') return _.filter(Dicts.visitType(), (t) => {
        return t.value.includes('pielegniarska') || t.value.includes('kontrolna')
    })
    return Dicts.visitType()
}

export const decodeVisitType = (key) => {
    let value = _.first(_.filter(Dicts.visitType(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodePlannedVisit = (key) => {
    let value = _.first(_.filter(Dicts.plannedVisit(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeColorClassification = (key) => {
    let value = _.first(_.filter(Dicts.colorClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeBiofilmClassification = (key) => {
    let value = _.first(_.filter(Dicts.biofilmClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeAllowToTrade = (key) => {
    let value = _.first(_.filter(Dicts.allowToTrade(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeWoundEtiology = (key) => {
    let value = _.first(_.filter(Dicts.woundEtiology(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeStepClassification = (key) => {
    let value = _.first(_.filter(Dicts.stepClassification(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeMannequin = (key) => {
    let value = _.first(_.filter(Dicts.mannequin(), (p) => {
        return p.value === key
    }))?.label || i18n.t('dictMannequin.notFound')
    return value ? value : ''
}

export const decodeApplicationType = (key) => {
    let value = _.first(_.filter(Dicts.applicationType(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeBoolean = (key) => {
    let value = _.first(_.filter(Dicts.booleanType(), (p) => {
        return p.value === key
    }))
    return value ? value.label : ''
}

export const decodeLanguage = (key) => {
    let value = _.first(_.filter(Dicts.languageList(), (p) => {
        return p.code === key
    }))
    return value ? value.label : ''
}

export const getMomentCode = (key) => {
    let value = _.first(_.filter(Dicts.languageOriginalList(), (p) => {
        return p.code === key
    }))
    return value ? value.moment : ''
}
