import React, {Component} from 'react';
import {AppWrapper} from "app/app-main/AppWrapper";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {BrowserRouter as Router} from "react-router-dom";
import Toasts from "../app-components/common/Toasts";
import Keycloak from "keycloak-js";
import {PersonnelApi} from "../api/PersonnelApi";
import {I18nextProvider} from "react-i18next";
import i18n from "../i18n";
import Dicts from "../app-components/patient/Dicts";


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userLoaded: false,
            authenticated: false
        };

    }

    updateDimensions = () => {
        this.props.setWindowSize(window.innerWidth, window.innerHeight);
        if (window.innerWidth > 991) {
            this.props.openSidebar()
        } else {
            this.props.closeSidebar()
        }
    };

    componentDidMount() {
        const keycloak = Keycloak('/keycloak.json');
        keycloak.init({
            onLoad: 'login-required',
            redirectUri: window.var.WEB_HOST //zawsze po zalogowaniu wyrzucamy na /
        }).then(authenticated => {
            setInterval(() => keycloak.updateToken(60), 30)
            this.props.setKeycloak(keycloak);
            this.setState({authenticated: authenticated})
            let roles = keycloak.resourceAccess.core.roles;

            if (!roles.includes('personnel') && !roles.includes('receptionist') && !roles.includes('admin')) {
                this.props.clearUser();
                this.props.keycloak.logout();
            }

            keycloak.loadUserInfo().then(userInfo => {
                PersonnelApi.getPersonnel(userInfo.sub).then(r => {
                    this.props.setUser({userInfo: {...userInfo, ...r.data}, authorities: roles});
                })

                PersonnelApi.getPersonnelPreferences().then(json => {
                    const lang = json.data?.lang?.webApp
                    const langExist = Dicts.languageList().find(f => {
                        return f.value === lang
                    })
                    if (lang !== undefined && langExist !== undefined) {
                        i18n.changeLanguage(lang).then(_ => console.log("language change to default preference"))
                    }
                })

                this.setState({userLoaded: true});

            });

        })

        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    render() {

        if (this.appNotReady()) return this.getLoader(true);


        return (
            <I18nextProvider i18n={i18n}>
                <div>
                    {this.getLoader(this.props.loading)}
                    <Router>
                        <AppWrapper/>
                    </Router>

                    {/*<DebugSidebar/>*/}
                    <Toasts/>
                </div>
            </I18nextProvider>
        )
    }

    appNotReady() {
        return !this.state.userLoaded;
    }

    getLoader(isLoading) {
        return <div className="loader" style={{display: isLoading ? 'block' : 'none'}}>
            <div className="loader-top"/>
            <div className="spinner-grow text-info spinner-lg" role="status"/>
        </div>;
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(App);


