import {Api} from "./Api";

export class AdminReportsApi extends Api {

    static apiUrl = window.var.API_HOST + '/api/v1/admin/report';

    static getPlanimetryReport(form) {
        return AdminReportsApi.post("/planimetry/check", form)
    }

    static sendPlanimetryReport(form) {
        return AdminReportsApi.post("/planimetry/send", form)
    }

    static getAllVisitsReport(clinicId, from, to) {
        return AdminReportsApi.getFile(`/visits/all/${clinicId}?from=${from}&to=${to}`)
    }

    static getPersonnelVisitsReport(from, to) {
        return AdminReportsApi.getFile(`/visits/personnel?from=${from}&to=${to}`)
    }
}

