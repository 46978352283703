import ViewPersonnelWounds from "../app-views/authorized/personnel/ViewPersonnelWounds";
import {useTranslation} from "react-i18next";
import {lazy} from "react";

const ViewComorbidities = lazy(() => import("../app-views/authorized/personnel/ViewComorbidities"))
const ViewPatient = lazy(() => import("../app-views/authorized/personnel/ViewPatient"))
const ViewVisits = lazy(() => import("../app-views/authorized/personnel/ViewVisits"))
const ViewNewVisit = lazy(() => import("../app-views/authorized/personnel/ViewNewVisit"))
const VisitFromCalendar = lazy(() => import("../app-views/authorized/personnel/VisitFromCalendar"))
const ViewNewVisitPlanimetry = lazy(() => import("../app-views/authorized/personnel/ViewNewVisitPlanimetry"))
const ViewMedicalDocumentation = lazy(() => import("../app-views/authorized/personnel/ViewMedicalDocumentation"))
const ViewPlanimetryPhotos = lazy(() => import("../app-views/authorized/personnel/ViewPlanimetryPhotos"))
const ViewMedicalExamination = lazy(() => import("../app-views/authorized/personnel/ViewMedicalExamination"))

const usePersonnelPatientRoutes = (props) => {
    const {t} = useTranslation()

    return [

        {
            key: 'viewPatient',
            exact: true,
            path: "/viewPatient",
            has: ['md', 'nurse', 'receptionist'],
            component: ViewPatient,
            sidebar: true,
            label: t("sidebar.personnel.patientData"),
            icon: 'fas fa-address-card',
            shouldSensitiveDataVisible: true,
            showOnMobile: true,
            featureFlagsShow: true
        },

        {
            key: 'comorbidities',
            exact: true,
            path: "/comorbidities",
            has: ['md', 'nurse'],
            component: ViewComorbidities,
            sidebar: true,
            label: t("sidebar.personnel.comorbidities"),
            icon: 'fas fa-disease',
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'visits',
            exact: true,
            path: "/visits",
            has: ['md', 'nurse'],
            component: ViewVisits,
            sidebar: true,
            label: t("sidebar.personnel.visits"),
            icon: 'fas fa-hospital-user',
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'newVisit',
            exact: true,
            path: "/newVisit",
            has: ['md', 'nurse'],
            component: ViewNewVisit,
            sidebar: false,
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'newVisitPlanimetry',
            exact: true,
            path: "/newVisitPlanimetry",
            has: ['md', 'nurse'],
            component: ViewNewVisitPlanimetry,
            sidebar: false,
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: props?.featureFlags?.planimetry
        },
        {
            key: 'scheduledVisit',
            exact: true,
            path: "/scheduledVisit",
            has: ['md', 'nurse'],
            component: VisitFromCalendar,
            sidebar: false,
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: true
        },
        {
            key: 'documentations',
            exact: true,
            path: "/medicalDocumentations",
            has: ['md', 'nurse'],
            component: ViewMedicalDocumentation,
            sidebar: true,
            label: t("sidebar.personnel.medicalRecords"),
            icon: 'fas fa-file-medical-alt',
            shouldSensitiveDataVisible: true,
            showOnMobile: true,
            featureFlagsShow: true
        },
        {
            key: 'planimetryPhotos',
            exact: true,
            path: "/planimetryPhotos",
            has: ['md', 'nurse'],
            component: ViewPlanimetryPhotos,
            sidebar: true,
            label: t("sidebar.personnel.planimetryPhotos"),
            icon: 'fas fa-images',
            shouldSensitiveDataVisible: true,
            showOnMobile: true,
            featureFlagsShow: props?.featureFlags?.planimetry === false ? props?.featureFlags?.planimetry : true
        },
        {
            key: 'medicalExaminaton',
            exact: true,
            path: "/medicalExaminaton",
            has: ['md', 'nurse'],
            component: ViewMedicalExamination,
            sidebar: true,
            label: t("sidebar.personnel.medicalExams"),
            icon: 'fas fa-notes-medical',
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: props?.featureFlags?.medicalExamination === false ? props?.featureFlags?.medicalExamination : true
        },
        {
            key: 'personnelWounds',
            exact: true,
            path: "/wounds",
            has: ['md', 'nurse'],
            component: ViewPersonnelWounds,
            sidebar: true,
            label: t("sidebar.personnel.wounds"),
            icon: 'far fa-band-aid',
            shouldSensitiveDataVisible: true,
            showOnMobile: false,
            featureFlagsShow: props?.featureFlags?.woundExamination === false ? props.featureFlags.woundExamination : true
        },
    ]
}

export default usePersonnelPatientRoutes
