import React, {useState} from 'react';
import "assets/css/mannequin.css"
import {Col, FormFeedback, FormGroup, Label} from "reactstrap";
import {getMsg, isValid} from "./Validation";
import {connect} from "react-redux";
import {female_svg} from "assets/female_svg"
import {male_svg} from "assets/male_svg"
import {mapStorageDispatchToProps, mapStorageToProps} from "../../redux/mappers";
import * as PropTypes from "prop-types";
import {getValueFromFormOrDefault} from "./Form/FormUtils";
import _ from 'lodash'
import {decodeMannequin} from "../patient/DictsDecode";

const pathStyle = {
    stroke: "none",
    strokeWidth: 0.1
}

const partStyle = {
    fill: "rgba(255,255,255,0)",
    strokeOpacity: 1,
    stroke: "none",
    strokeWidth: 0.1
}

const Mannequin = (props) => {

    let invalid = isValid(props.field);
    const [parts, setParts] = useState(props.field ?
        _.keys(_.pickBy(getValueFromFormOrDefault(props.formId, props.field, {}), (selected, place)=>{return selected && place}))
        : []);

    const drawSvg = (sex_body_image, scale, size) => {
        return <svg style={{width: 240 * size, height: 310 * size}}>
            <g transform={`scale(${scale * size})`}>
                {sex_body_image.map((path) => {
                    return path.selectable ? <path
                        key={path.id}
                        style={partStyle}
                        id={path.id}
                        d={path.d}
                        className={"body-part " + (parts.includes(path.id) && "active-part")}
                        onClick={togglePart}
                    >
                        {path.selectable && <title>{decodeMannequin(path.id)}</title>}
                    </path> : <path
                        key={path.id}
                        style={pathStyle}
                        id={path.id}
                        d={path.d}
                    />
                })}
            </g>
        </svg>
    }

    const drawSelectedSvg = (sex_body_image, scale, size) => {
        return <svg style={{width: 120 * size, height: 155 * size}}>
            <g transform={`scale(${scale * size})`}>
                {sex_body_image.map((path) => {
                    return path.selectable ? <path
                        key={path.id}
                        style={partStyle}
                        id={path.id}
                        d={path.d}
                        className={(props.placement[path.id] ? "active-part" : "")}
                    >
                        {path.selectable && <title>{decodeMannequin(path.id)}</title>}
                    </path> : <path
                        key={path.id}
                        style={pathStyle}
                        id={path.id}
                        d={path.d}
                    />
                })}
            </g>
        </svg>
    }

    const togglePart = (event) => {
        if (parts.indexOf(event.target.id) >= 0) {
            let list = parts.filter((item) => item !== event.target.id);
            setParts(list)
            props.setValueOnForm(props.formId, props.field, Object.assign(props.form[props.formId][props.field] || {}, {[event.target.id]: false}))
        } else {
            let list = parts.concat(event.target.id);
            setParts(list)
            props.setValueOnForm(props.formId, props.field, Object.assign(props.form[props.formId][props.field] || {}, {[event.target.id]: true}))
        }
    }

    return props.thumbnail ?
        <div>
            {props.context.patient.personalData.sex === "kobieta" ? drawSelectedSvg(female_svg, 0.15, props.size) : drawSelectedSvg(male_svg, 0.15, props.size)}
        </div>
        :
        <div className={"col-md-12 mt-2 col-lg-" + props.colLg}>
            <FormGroup row>
                <Label for={props.formId + '.' + props.field} sm={3}
                       className="text-md-right my-auto text-label">{props.label}{props.required &&
                <span className="fred pl-1">*</span>}</Label>
                <Col sm={9} className="my-auto">

                    <div>
                        {props.context.patient.personalData.sex === "kobieta" ? drawSvg(female_svg, 0.35, props.size) : drawSvg(male_svg, 0.3, props.size)}
                    </div>
                    {invalid && <FormFeedback style={{
                        display: "block",
                        marginLeft: '10px'
                    }}>{getMsg(props.field)}</FormFeedback>}

                </Col>
            </FormGroup>
        </div>
}

Mannequin.defaultProps = {
    colLg: 6,
    size: 1
};

Mannequin.propTypes = {
    formId: PropTypes.string,
    field: PropTypes.string,
    thumbnail: PropTypes.bool,
    required: PropTypes.bool,
    placement: PropTypes.object,
    colLg: PropTypes.number,
    label: PropTypes.string,
    size: PropTypes.number
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Mannequin)
