import AppModal from "./AppModal"
import React, {useEffect} from "react"
import {FormSection} from "../app-components/common/Form/FormSection"
import {FormRow} from "../app-components/common/Form/FormRow"
import {useTranslation} from "react-i18next"
import SelectFlagIcon from "../app-components/flag/SelectFlagIcon"
import {PersonnelApi} from "../api/PersonnelApi"
import {withRouter} from "react-router-dom"
import i18n from "i18next"
import {Form} from "reactstrap"
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../redux/mappers";


const AppLanguage = (props) => {

    const {t} = useTranslation()

    useEffect(() => {
        if (props.isOpen) {
            PersonnelApi.getPersonnelPreferences().then(json => {
                let webAppLang = json.data?.lang?.webApp

                let preferences = {...json.data, lang: {...json.data?.lang, webApp: webAppLang !== undefined ? webAppLang : i18n.resolvedLanguage}}

                props.setPreferences(preferences)
            })
        }

    }, [props.isOpen])


    const saveLanguages = () => {

        PersonnelApi.updatePersonnelPreferences(props.preferences).then(json => {
            if (json.meta.status === "SUCCESS") {
                i18n.changeLanguage(props.preferences.lang.webApp).then(_ => {
                    console.debug("Language was changed", props.preferences.lang.webApp)
                    props.toggle()

                })

                PersonnelApi.getPersonnelPreferences().then(json => {
                    props.setPreferences(json.data)
                })
            }
        })
    }


    return <>
        <AppModal isOpen={props.isOpen} saveText={t("commonForm.buttonSave")}
                  toggle={props.toggle}
                  cancelIcon={"fas fa-times"}
                  saveIcon={"fas fa-check"}
                  saveAction={saveLanguages}>
            <Form>
                <FormSection>
                    <FormRow>
                        <SelectFlagIcon required
                                        label={t("appLanguage")}
                                        initLangCode={props.preferences?.lang?.webApp}
                                        setLangHandler={(lang) => {
                                            let preferences = {...props.preferences, lang: {...props.preferences?.lang, webApp: lang.code}}
                                            props.setPreferences(preferences)
                                        }}
                                        colLg={12}
                        />
                    </FormRow>
                </FormSection>
            </Form>
        </AppModal>
    </>
}
export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppLanguage));
