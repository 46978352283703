import React from 'react';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {Link, withRouter} from "react-router-dom";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar} from "reactstrap";
import {Image} from "react-bootstrap";
import logo from "../../assets/img/topbar/logo.png";
import AppTopbarClinic from "./AppTopbarClinic";
import AppTopbarVisit from "./AppTopbarVisit";
import {AuthenticatedLink} from "../app-components/common/AuthenticatedLink";
import {Translation} from 'react-i18next';
import {AppMobileVersion} from "./AppMobileVersion";
import AppLanguage from "./AppLanguage";

class AppTopbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
            langModalIsOpen: false,
            getMobileAppModalOpen: false
        };
        this.goToHome = this.goToHome.bind(this);
    }

    logout = () => {
        this.props.clearUser();
        this.props.clearContext();
        this.props.history.push('/');
        this.props.keycloak.logout();
    };

    account = () => {
        this.props.history.push('/account');
    };

    toggleSidebar = () => {
        this.props.toggleSidebar()
    };

    goToHome() {
        this.props.history.push('/');
    };

    toggle = () => {
        this.setState({dropDownOpen: !this.state.dropDownOpen})
    }

    toggleGetMobileVersion = () => {
        this.setState({getMobileAppModalOpen: !this.state.getMobileAppModalOpen})
    }


    render() {
        return <Translation>
            {(t, {i18n}) =>
                <>
                    <AppMobileVersion isOpen={this.state.getMobileAppModalOpen}
                                      toggle={this.toggleGetMobileVersion}
                    />

                    <AppLanguage isOpen={this.state.langModalIsOpen}
                                 toggle={() => this.setState({langModalIsOpen: false})}/>

                    <Navbar color="light" light expand
                            className="bg-white shadow-sm topbar static-top fw600 fblack py-0"
                            style={{height: 60}}>
                        {this.props.windowSize.width < 991 &&
                            <>
                                <div style={{width: "20%", height: "100%"}} className="d-flex align-items-center"
                                     onClick={
                                         this.toggleSidebar
                                     }>
                                    <i className="fas fa-bars fa-lg pl-2" style={{verticalAlign: "middle"}}/>
                                </div>
                                <Nav className="mr-auto" navbar>
                                </Nav>
                            </>
                        }
                        <Link to="/">
                            <div>
                                <Image className="align-items-center d-inline pointer pr-2" src={logo}
                                       style={{height: "40px"}}/>
                            </div>
                        </Link>

                        {(this.props.user && this.props.user.userInfo) ? <AppTopbarClinic/> : ''}
                        {this.props.windowSize.width > 991 ?
                            <>
                                {this.props.context.patient &&
                                    <span style={{borderRight: "1px solid #DDD"}}
                                          className="p-0 pr-3 pl-3 h-100 align-items-center d-flex">
                            <div style={{
                                filter: this.props.isSensitiveDataVisible ? null : "blur(5px)",
                                userSelect: this.props.isSensitiveDataVisible ? null : "none"
                            }}>
                            <span className="pr-2">{t("PATIENT")}:</span>
                            <span className="pr-1">
                                {this.props.context.patient.personalData.firstname} {this.props.context.patient.personalData.lastname}
                            </span>
                            <span
                                data-test={window.var.DEV_MODE ? 'pesel-navbar' : null}>{t("PESEL")}: {this.props.context.patient.personalData.pesel}</span>
                            </div>
                            <span className="pl-2">
                                <span onClick={() => {
                                    this.props.clearContext()
                                    this.goToHome();
                                }} className=" pointer text-gray-600 p-0">
                                    <i className="far fa-times-circle fa-lg"/>
                                </span>
                            </span>
                        </span>
                                }
                                {this.props.context.patient ? <AppTopbarVisit/> : ''}

                                <Nav className="mr-auto" navbar>
                                </Nav>
                                <Dropdown isOpen={this.state.dropDownOpen} className="" toggle={this.toggle}>
                        <span className="p-0 pr-3 pl-3 h-100 align-items-center d-flex">
                            {this.props.user.userInfo ? (this.props.user.userInfo.degree ? this.props.user.userInfo.degree : '') + " " + this.props.user.userInfo.firstname + " " + this.props.user.userInfo.lastname : ''}
                            <DropdownToggle tag="span">
                            <i className="fal fa-user ml-4 mr-1 fwhite circle-icon pointer"/>
                                {this.state.dropDownOpen ? <i className="fal fa-chevron-up pointer"/> :
                                    <i className="fal fa-chevron-down pointer"/>}
                            </DropdownToggle>
                        </span>

                                    <DropdownMenu className="mt-3 text-gray-400">
                                        <DropdownItem className="py-2" onClick={this.account}>
                                            <div className="row text-gray-700">
                                                <div className="col-3 text-center"><i
                                                    className="far fa-user-edit fa-lg"/></div>
                                                <div className="col-9">{t("myData")}</div>
                                            </div>
                                        </DropdownItem>
                                        {this.props.tenant.featureFlags?.mobileApp &&
                                            <DropdownItem className="py-2" onClick={this.toggleGetMobileVersion}>
                                                <div className="row text-gray-700">
                                                    <div className="col-3 text-center"><i
                                                        className="far fa-mobile fa-lg"/>
                                                    </div>
                                                    <div className="col-9">{t("mobileApp")}
                                                    </div>
                                                </div>
                                            </DropdownItem>}
                                        {this.props.tenant.featureFlags?.planimetry && <DropdownItem className="py-2">
                                            <AuthenticatedLink
                                                url={"/api/v1/personnel/download/qrcode"}
                                                filename={"qr.pdf"}>
                                                <div className="row text-gray-700">
                                                    <div className="col-3 text-center"><i
                                                        className="far fa-qrcode fa-lg"/>
                                                    </div>
                                                    <div className="col-9">{t("qr")}</div>
                                                </div>
                                            </AuthenticatedLink>
                                        </DropdownItem>}
                                        <DropdownItem className="py-2"
                                                      onClick={() => this.setState({langModalIsOpen: true})}>
                                            <div className="row text-gray-700">
                                                <div className="col-3 text-center">
                                                    {i18n.resolvedLanguage.toUpperCase()}
                                                </div>
                                                <div className="col-9">{t("languages")}</div>
                                            </div>
                                        </DropdownItem>
                                        <DropdownItem className="py-2" onClick={this.logout}>
                                            <div className="row text-gray-700">
                                                <div className="col-3 text-center"><i
                                                    className="far fa-sign-out-alt fa-lg"/>
                                                </div>
                                                <div className="col-9">{t("logout")}</div>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </> : null}
                    </Navbar></>
            }
        </Translation>
    }
}


export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppTopbar));
