import React from "react";

const ErrorPage = ({error}) => {
    return <>
        {error.name === "ChunkLoadError" ?
            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h1 className="display-4">Proszę odświeżyć stronę</h1>
                    <p className="lead">{error.name}</p>
                </div>
            </div> : <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h1 className="display-4">Coś poszło nie tak :(</h1>
                    <p className="lead">{error.name}</p>
                </div>
            </div> }</>
}
export default ErrorPage;