import {Api} from "./Api";

export class IssueApi extends Api {


    static apiUrl = window.var.API_HOST + '/api/v1/personnel/issues';

    static addIssue(form) {
        return IssueApi.post("/add", form)
    }
    static checkConnection() {
        return IssueApi.get("/check")
    }
}