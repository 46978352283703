import React, {Suspense, useEffect} from "react";
import {Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import AppSecureRoute from "./AppSecureRoute";
import {useErrorBoundary} from "use-error-boundary";
import Loader from "../app-components/common/Loader";
import ErrorPage from "../app-components/common/ErrorPage";
import _ from "lodash";
import useAdminRoutes from "../hooks/useAdminRoutes";
import usePersonnelRoutes from "../hooks/usePersonnelRoutes";
import usePersonnelPatientRoutes from "../hooks/usePersonnelPatientRoutes";
import {TenantApi} from "../api/TenantApi";

const AppContent = (props) => {
    const {
        ErrorBoundary,
        didCatch,
        error
    } = useErrorBoundary()

    useEffect(() => {
        if (props.user.clinic?.tenantId !== undefined) {
            TenantApi.getTenant(props.user.clinic?.tenantId).then(json => {
                console.log('tenants', json.data)
                props.setTenant(json.data);
            })
        }

    }, [props.user])


    const routesAdmin = useAdminRoutes(props.tenant)
    const routes = usePersonnelRoutes(props.tenant)
    const routesPersonnelPatient = usePersonnelPatientRoutes(props.tenant)


    let checkAuthority = (r) => {
        if (!props.user.authorities) return false
        const userAuthorities = props.user.authorities

        const has = _.filter(r.has, function (p) {
            return userAuthorities.includes(p)
        }).length > 0

        const noHas = r.noHas ? _.filter(r.noHas, function (p) {
            return userAuthorities.includes(p)
        }).length === 0 : true

        const andHas = r.andHas ? _.filter(r.andHas, function (p) {
            return userAuthorities.includes(p)
        }).length > 0 : true

        return has && noHas && andHas
    }

    return <>
        <div id="content-wrapper" className="py-3 px-3" style={{overflowX: "initial"}}>
            {didCatch ? <ErrorPage error={error}/> :
                <ErrorBoundary>
                    <Suspense fallback={<Loader/>}>
                        {props.tenant && <Switch>
                            {routes
                                .filter((r) => checkAuthority(r))
                                .map((r) => {
                                    return <AppSecureRoute id={r.path} {...r} />;
                                })}

                            {props.context.patient ? routesPersonnelPatient
                                .filter((r) => checkAuthority(r))
                                .filter(r => r.featureFlagsShow)
                                .map((r) => {
                                    return <AppSecureRoute id={r.path} {...r} />;
                                }) : ''}

                            {routesAdmin
                                .filter(r => r.featureFlagsShow)
                                .map((r) => {
                                    return <AppSecureRoute id={r.path} {...r} />;
                                })}
                        </Switch>}
                    </Suspense>
                </ErrorBoundary>
            }
        </div>
    </>
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppContent));