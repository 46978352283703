import React, {Component} from "react";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../../redux/mappers";
import * as PropTypes from "prop-types";


class FormHeaderExtended extends Component {

    render() {
        return <>
            <div className={'card-header ' + this.props.bgColor + ' ' + this.props.className}>
                <div className={"h5 fwhite fw600 pl-2 " + this.props.classNameInside}>
                    {this.props.children}
                </div>
            </div>
        </>;
    }
}

FormHeaderExtended.defaultProps = {
    bgColor: 'bg-secondary',
    className: '',
    classNameInside: ''
}

FormHeaderExtended.propTypes = {
    children: PropTypes.node,
    bgColor : PropTypes.string,
    className: PropTypes.string,
    classNameInside: PropTypes.string
};

export default connect(mapStorageToProps, mapStorageDispatchToProps)(FormHeaderExtended)